import { I18n } from 'i18n-js'

import en from './locales/en.json'
import it from './locales/it.json'
import es from './locales/es.json'

const langArray = ['en', 'es', 'it']

const lang = navigator.language
let langCode
if (lang == null) {
    langCode = 'en'
} else {
    langCode = lang.substring(0, 2)
    if (!langArray.includes(langCode)) {
        langCode = 'en'
    }
}

export const i18n = new I18n()
i18n.defaultLocale = 'en'
i18n.locale = langCode
i18n.enableFallback = true
i18n.translations = {en, es, it}

export default i18n