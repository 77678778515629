import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export default function makeCall(method, api, body) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json'
        }
        axios({
            method: method,
            url: API_URL + api,
            data: body,
            headers: headers
        }).then(response => resolve(response.data),
            error => {
                error.response ? reject(error.response.data) : reject(error) //Il secondo è un errore in caso di mancata connessione
            })
    })
}