import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import {Typography} from 'antd'

const {Title, Paragraph, Text} = Typography

export default function TermsOfServicePage() {
    return (
        <div className="TermsOfServicePage">
            <Navbar/>
            <div className="page-section" style={{paddingTop: 170}}>
                <div className="container">
                    <Title>Live Bingo Terms of Services</Title>
                    <Title level={3}>The Gist</Title>
                    <Paragraph>
                        We, the folks at Dried Beans, are the creators of Live Bingo. Our goal is helping brands and
                        streamers connecting with their viewers with engaging interactive live contents. We hope that
                        you like our projects! However, to ensure your safety and ours, there are limitations on how you
                        may use these Services. These limitations are the Terms of Service.
                    </Paragraph>
                    <Paragraph>
                        The last substantive update to this document (excluding formatting changes, typo fixes, etc.)
                        was on <Text strong>2021-04-09</Text>.
                    </Paragraph>
                    <Title level={3}>1. Terms of Service</Title>
                    <Paragraph>
                        These Terms of Service (“Terms”), which include and hereby incorporate the Privacy Policy
                        (“Privacy Policy”), are a legal agreement between Dried Beans, and its related companies and
                        affiliates (the “Company”, “Dried Beans” or “we”) and you (“you”). By using or accessing the
                        Service, or any other websites, APIs, applications, or services offered by the Company, which
                        are collectively referred to as the “Service,” you agree (i) that you are 18 years of age or
                        older, (ii) if you are the age of majority in your jurisdiction or over, that you have read,
                        understood, and accept to be bound by the Terms, and (iii) if you are between 18 and the age of
                        majority in your jurisdiction, that your legal guardian has reviewed and agrees to these Terms.
                    </Paragraph>
                    <Paragraph>
                        The Company reserves the right, in its sole discretion, to modify or revise these Terms at any
                        time, and you agree to be bound by such modifications or revisions. Any such change or
                        modification will be effective immediately upon posting on the Service, and your continued use
                        of the Service after any changes or modifications to these Terms are posted will constitute your
                        acceptance of, and agreement to, such changes or modifications. If you object to any change or
                        modification, your sole recourse shall be to cease using the Service.
                    </Paragraph>
                    <Paragraph>
                        Please read these Terms carefully before accessing or using our Services. By accessing or using
                        any part of our Services, you agree to become bound by the Terms set forth herein. If you do not
                        agree to all the terms and conditions of this agreement, then you may not access or use the
                        Service.
                    </Paragraph>
                    <Title level={3}>2. Access to Service</Title>
                    <Paragraph>
                        The Service provides online, desktop, and mobile platforms designed to help you create an
                        engaging interactive content for your viewers. The Service may allow you to generate, create, or
                        upload your own original content as well as content and data produced by playing and interacting
                        with the games we support on our platform. Subject to your compliance with these Terms, the
                        Company grants you a limited, revocable, non-exclusive, non-transferable, non-sublicensable
                        license to use and access the Service, solely for your personal, non-commercial use.
                    </Paragraph>
                    <Paragraph>
                        You agree not to (and not to attempt to) (i) use the Service for any use or purpose other than
                        as expressly permitted by these Terms, (ii) access any and all non API based services in a
                        manner that sends more request messages to the Service’s servers in a given period of time than
                        a human can reasonably produce in the same period by using a conventional on-line web browser,
                        or (ii) copy, adapt, modify, prepare derivative works based upon, distribute, license, sell,
                        transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit
                        the Service or any portion of the Service, except as expressly permitted in these Terms. No
                        licenses or rights are granted to you by implication or otherwise under any intellectual
                        property rights owned or controlled by the Company or its licensors, except for the permissions
                        and rights expressly granted in these Terms.
                    </Paragraph>
                    <Paragraph>
                        The Company reserves the right to modify or discontinue, temporarily or permanently, the Service
                        (or any part thereof) with or without notice. The Company will make all reasonable efforts to
                        keep the Service online and available, however it makes no guarantees of uptime or reliability.
                        The Company reserves the right to refuse any user access to the Services without notice for any
                        reason or no reason, including, but not limited to, a violation of the Terms.
                    </Paragraph>
                    <Paragraph>
                        If you violate these Terms, the Company reserves the right to issue you a warning regarding the
                        violation or immediately terminate or suspend any or all accounts you have created using the
                        Service. You agree that the Company need not provide you notice before terminating or suspending
                        your account(s), but the Company may do so in its sole discretion.
                    </Paragraph>
                    <Title level={3}>3. Your Account</Title>
                    <Paragraph>
                        Use of some of our Services requires an account. You agree that any and all personal information
                        you provide to us is complete and accurate, both at the time of registration and during
                        continued use of our Services. You will be solely responsible and liable for any activity that
                        occurs on your account and/or under your username. You are responsible for maintaining the
                        security of your account, and you are fully responsible for all activities that occur under the
                        account. You must immediately notify us of any unauthorized uses of your account, by e-mailing
                        us at <a href="mailto:driedbeans@livebingo.app" target="_blank"
                                 rel="noopener noreferrer">driedbeans@livebingo.app</a>.
                    </Paragraph>
                    <Title level={3}>4. Responsibility of Users</Title>
                    <Paragraph>
                        You are entirely responsible for the content you produce, and any harm resulting from, that
                        Content. That is the case regardless of what form or media the Content takes, which includes,
                        but is not limited to text, photo, video, audio, structured and unstructured data formats, or
                        code. By using the Service, you represent and warrant that your Content and conduct do not
                        violate these terms.
                    </Paragraph>
                    <Paragraph>
                        If you delete Content, Dried Beans will use reasonable efforts to remove it from the Service,
                        but you acknowledge that caching or references to the Content may not be made immediately
                        unavailable. We recommend using the proper Twitch category while streaming a certain game with
                        our Service active as a Twitch extension.
                    </Paragraph>
                    <Title level={3}>5. Responsibility of Visitors</Title>
                    <Paragraph>
                        Dried Beans has not reviewed, and cannot review, all of the material, including computer
                        software, posted to our Services, and cannot therefore be responsible for that material’s
                        content, use or effects. By operating our Services, Dried Beans does not represent or imply that
                        it endorses the material there posted, or that it believes such material to be accurate, useful,
                        or non-harmful. You are responsible for taking precautions as necessary to protect yourself and
                        your computer systems from viruses, worms, Trojan horses, and other harmful or destructive
                        content. Our Services may contain content that is offensive, indecent, or otherwise
                        objectionable, as well as content containing technical inaccuracies, typographical mistakes, and
                        other errors. Our Services may also contain material that violates the privacy or publicity
                        rights, or infringes the intellectual property and other proprietary rights, of third parties,
                        or the downloading, copying or use of which is subject to additional terms and conditions,
                        stated or unstated. Dried Beans disclaims any responsibility for any harm resulting from the use
                        by visitors of our Services, or from any downloading by those visitors of content there posted.
                    </Paragraph>
                    <Title level={3}>6. Content Posted on Other Websites</Title>
                    <Paragraph>
                        We have not reviewed, and cannot review, all of the material, including computer software, made
                        available through the websites and webpages to which the Service links, and that link to the
                        Service. Dried Beans does not have any control over those non-Service websites, and is not
                        responsible for their contents or their use. By linking to a non-Service website, Dried Beans
                        does not represent or imply that it endorses such website. You are responsible for taking
                        precautions as necessary to protect yourself and your computer systems from viruses, worms,
                        Trojan horses, and other harmful or destructive content. Dried Beans disclaims any
                        responsibility for any harm resulting from your use of non-Service websites and webpages.
                    </Paragraph>
                    <Title level={3}>7. Intellectual Property</Title>
                    <Paragraph>
                        This agreement does not transfer from Dried Beans to you any Dried Beans or third party
                        intellectual property, and all right, title, and interest in and to such property will remain
                        (as between the parties) solely with Dried Beans, and all other trademarks, service marks,
                        graphics and logos used in connection with our Services, are trademarks or registered trademarks
                        of Dried Beans or Dried Beans’s licensors. Other trademarks, service marks, graphics and logos
                        used in connection with our Services may be the trademarks of other third parties. Your use of
                        our Services grants you no right or license to reproduce or otherwise use any Dried Beans or
                        third-party trademarks.
                    </Paragraph>
                    <Paragraph>
                        You agree that you shall not modify, copy, distribute, frame, reproduce, republish, download,
                        scrape, display, post, transmit, or sell in any form or by any means, in whole or in part, or
                        otherwise exploit the Service without our express prior written permission. You acknowledge that
                        you do not acquire any ownership rights by using the Service or by accessing any Premium Service
                        provided by the Company, or any derivative works thereof and you agree not to challenge the
                        validity of any of the Company’s intellectual property or the Company’s ownership thereof. All
                        rights not expressly granted by these Terms are reserved by the Company and its licensors, and
                        no license is granted hereunder by estoppel, implication or otherwise.
                    </Paragraph>
                    <Paragraph>
                        You further agree that by submitting Content to the Service, by any method, you hereby grant
                        Dried Beans a world-wide, royalty-free, perpetual and non-exclusive license to reproduce,
                        analyze, modify, adapt, publish, redistribute and sell the Content, both individual and
                        aggregated from the global player base. This license includes any data, information or creative
                        works which result from and/or are derivative of the Content.
                    </Paragraph>
                    <Title level={3}>8. Digital Millennium Copyright Act</Title>
                    <Paragraph>
                        If you believe that material available on our Site, including any Content hosted on any of our
                        Sites, infringes on your copyright(s), please notify us by providing a DMCA notice. Upon receipt
                        of a valid and complete notice, we will remove the material and make a good faith attempt to
                        contact the user who uploaded the material by email.
                    </Paragraph>
                    <Paragraph>
                        Please follow these steps to file a notice:
                        <ol>
                            <li>
                                <Text strong>Verify that the allegedly infringing material or Content is hosted by the
                                    Company.</Text> We have no control over third-party websites and/or to material or
                                Content that is not hosted on our servers.
                            </li>
                            <li>
                                <Text strong>Attempt to contact the alleged infringer directly </Text>to see if the
                                matter can be resolved directly between you and the user.
                            </li>
                            <li>
                                Send your complaint to <a href="mailto:driedbeans@livebingo.app" target="_blank"
                                                          rel="noopener noreferrer">driedbeans@livebingo.app</a> if the
                                issue cannot be resolved directly with the user. You must include the following
                                <ul>

                                    <li>
                                        A physical or electronic signature of the copyright owner or a person authorized
                                        to act on their behalf;
                                    </li>
                                    <li>
                                        An identification of the copyrighted work claimed to have been infringed;
                                    </li>
                                    <li>
                                        A description of the nature and exact location of the material that you claim to
                                        infringe your copyright, in sufficient detail to permit the Company to find and
                                        positively identify that material. For example, we require specific
                                        identification of the allegedly infringing user and a link to the specific
                                        location that contains the material and a description of which specific portion
                                        of the location – an image, a link, the text, etc. your complaint refers to;
                                    </li>
                                    <li>
                                        Your name, address, telephone number and email address;
                                    </li>
                                    <li>
                                        A statement that you have a good faith belief that use of the material in the
                                        manner complained of is not authorized by the copyright owner, its agent, or the
                                        law;
                                    </li>
                                    <li>
                                        A statement that the information in the notification is accurate, and under
                                        penalty of perjury, that you are authorized to act on behalf of the owner of an
                                        exclusive right that is allegedly infringed.
                                    </li>
                                    <li>
                                        As required by the DMCA, we have a policy to terminate users and/or accounts
                                        that we consider to be repeat infringers. Although we don’t share the specifics
                                        of our repeat infringer policy, we believe that it strikes the right balance of
                                        protecting the rights of copyright owners as well as protecting legitimate users
                                        from wrongful termination. Please note that notices that are successfully
                                        countered, rejected on fair use grounds, or deemed to be fraudulent are not
                                        counted against a user or account.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </Paragraph>
                    <Title level={3}>9. Feedback</Title>
                    <Paragraph>
                        We appreciate hearing from our users and welcome your comments regarding the Service. Please be
                        advised, however, that if you send to us any comments, suggestions or recommendations regarding
                        the Service (“Feedback”), particularly through any “Contact” functionality on our Sites or
                        through our Service, you hereby assign to us all rights, title and interest in and to the
                        Feedback. Accordingly, your Feedback will not be subject to any obligation of confidentiality
                        and we will not be liable to you for any use or disclosure of any Feedback. Also, we will be
                        entitled and are hereby licensed to unrestricted use of the Feedback for any purpose whatsoever,
                        commercial or otherwise, without compensation to you or any other person.
                    </Paragraph>
                    <Title level={3}>10. Termination</Title>
                    <Paragraph>
                        Dried Beans may terminate your access to all or any part of our Services at any time, with or
                        without cause, with or without notice, effective immediately. You may, as the result of
                        termination, lose your account, Content and all information and data associated therewith, as
                        applicable and the Company is under no obligation to compensate you for any such loss. If you
                        wish to terminate this agreement or any accounts on the Service you may have, you may simply
                        discontinue using our Services. All provisions of this agreement which by their nature should
                        survive termination shall survive termination, including, without limitation, ownership
                        provisions, warranty, disclaimers, indemnity and limitations of liability.
                    </Paragraph>
                    <Title level={3}>11. Disclaimer of Warranties</Title>
                    <Paragraph>
                        OUR SERVICES ARE PROVIDED “AS IS”. DRIED BEANS AND ITS SUPPLIERS AND LICENSORS HEREBY DISCLAIM
                        ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NEITHER DRIED BEANS NOR
                        ITS SUPPLIERS AND LICENSORS, MAKES ANY WARRANTY THAT OUR SERVICES WILL BE ERROR FREE OR THAT
                        ACCESS THERETO WILL BE CONTINUOUS OR UNINTERRUPTED. YOU UNDERSTAND THAT YOU DOWNLOAD FROM, OR
                        OTHERWISE OBTAIN CONTENT OR SERVICES THROUGH, OUR SERVICES AT YOUR OWN DISCRETION AND RISK.
                    </Paragraph>
                    <Title level={3}>12. Limitation of Liability</Title>
                    <Paragraph>
                        IN NO EVENT WILL DRIED BEANS, OR ITS SUPPLIERS OR LICENSORS, BE LIABLE WITH RESPECT TO ANY
                        SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER LEGAL
                        OR EQUITABLE THEORY FOR: (I) ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES; (II) THE COST OF
                        PROCUREMENT FOR SUBSTITUTE PRODUCTS OR SERVICES; (III) FOR INTERRUPTION OF USE OR LOSS OR
                        CORRUPTION OF DATA; OR (IV) FOR ANY AMOUNTS THAT EXCEED THE FEES PAID BY YOU TO DRIED BEANS
                        UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE CAUSE OF ACTION. DRIED
                        BEANS SHALL HAVE NO LIABILITY FOR ANY FAILURE OR DELAY DUE TO MATTERS BEYOND THEIR REASONABLE
                        CONTROL. THE FOREGOING SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                    </Paragraph>
                    <Title level={3}>13. General Representation and Warranty</Title>
                    <Paragraph>
                        You represent and warrant that (i) your use of our Services will be in strict accordance with
                        the Dried Beans Privacy Policy, with the Terms set forth in this agreement, and with all
                        applicable laws and regulations (including without limitation any local laws or regulations in
                        your country, state, city, or other governmental area, regarding online conduct and acceptable
                        content, and including all applicable laws regarding the transmission of technical data exported
                        from the country in which you reside) and (ii) your use of our Services will not infringe or
                        misappropriate the intellectual property rights of any third party.
                    </Paragraph>
                    <Title level={3}>14. Indemnification</Title>
                    <Paragraph>
                        You agree to indemnify and hold harmless Dried Beans, its contractors, and its licensors, and
                        their respective directors, officers, employees, and agents from and against any and all claims
                        and expenses, including attorneys fees, arising out of your use of our Services, including but
                        not limited to your violation of the Terms of this agreement.
                    </Paragraph>
                    <Title level={3}>15. Translation</Title>
                    <Paragraph>
                        These Terms of Service were originally written in English (US). We may translate these terms
                        into other languages. In the event of a conflict between a translated version of these Terms of
                        Service and the English version, the English version will control.
                    </Paragraph>
                    <Title level={3}>16. Attributions</Title>
                    <Paragraph>
                        These Terms of Service were adapted from the <a href="https://en.wordpress.com/tos/"
                                                                        target="_blank" rel="noopener noreferrer">Automattic
                        Terms of Service</a>, which are made available under a <a
                        href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank"
                        rel="noopener noreferrer">CC-BY-SA 4.0 license</a>.
                        <br/>
                        <br/>
                        <Title level={4}>Images</Title>
                        <a href="https://www.flaticon.com/free-icon/european-union_3014025" target="_blank"
                           rel="noopener noreferrer">European Union free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/united-states_3013934" target="_blank"
                           rel="noopener noreferrer">United States free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/auto_1687804" target="_blank"
                           rel="noopener noreferrer">Auto free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/influencer_1742695" target="_blank"
                           rel="noopener noreferrer">Influencer free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/profits_2936758" target="_blank"
                           rel="noopener noreferrer">Profits free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/themes_1982517" target="_blank"
                           rel="noopener noreferrer">Themes premium icon</a>,
                        <a href="https://www.flaticon.com/free-icon/favourite_2377810" target="_blank"
                           rel="noopener noreferrer">Favourite free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/podium_434876" target="_blank"
                           rel="noopener noreferrer">Podium free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/earth_2072130" target="_blank"
                           rel="noopener noreferrer">Earth free icon</a>,
                        <a href="https://www.flaticon.com/free-icon/popcorn_3418899" target="_blank"
                           rel="noopener noreferrer">Popcorn free icon</a>,
                        <a href="https://www.flaticon.com/packs/countrys-flags" target="_blank"
                           rel="noopener noreferrer">Country Flags Icon Pack</a>,
                        and <a href="https://www.flaticon.com/free-icon/animal-paw-print_64431" target="_blank"
                               rel="noopener noreferrer">Animal Paw Print free icon</a> made by <a
                        href="https://www.flaticon.com/authors/freepik" target="_blank"
                        rel="noopener noreferrer">Freepik</a> from <a href="https://www.flaticon.com/"
                                                                      title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/free-icon/enable_3597112" target="_blank"
                           rel="noopener noreferrer">Enable free icon</a> made by <a href="https://icon54.com/"
                                                                                     title="Pixel perfect">Pixel
                        perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/free-icon/bean_2227386" target="_blank"
                           rel="noopener noreferrer">Bean free icon</a> made by <a
                        href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a> from <a
                        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/packs/animal-27" target="_blank" rel="noopener noreferrer">Animal
                            Icon Pack</a> made by <a href="https://www.flaticon.com/authors/flat-icons"
                                                     title="Flat Icons">Flat Icons</a> from <a
                        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/packs/christmas-32" target="_blank" rel="noopener noreferrer">Christmas
                            Icon Pack 1</a> made by <a href="https://www.flaticon.com/authors/flat-icons"
                                                       title="Flat Icons">Flat Icons</a> from <a
                        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/packs/christmas-83" target="_blank" rel="noopener noreferrer">Christmas
                            Icon Pack 2</a> made by <a href="https://www.flaticon.com/authors/flat-icons"
                                                       title="Flat Icons">Flat Icons</a> from <a
                        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/packs/winter-9" target="_blank" rel="noopener noreferrer">Winter
                            Icon Pack</a> made by <a href="https://www.flaticon.com/authors/flat-icons"
                                                     title="Flat Icons">Flat Icons</a> from <a
                        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://www.flaticon.com/free-icon/human-resources_994284" target="_blank"
                           rel="noopener noreferrer">Human Resources free icon</a> made by <a
                        href="https://www.flaticon.com/authors/monkik" title="monkik">monkik</a> from <a
                        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
                        <br/>
                        <a href="https://it.m.wikipedia.org/wiki/File:Pok%C3%A9_Ball_icon.svg" target="_blank"
                           rel="noopener noreferrer">Pokèball icon</a> made by <a
                        href="https://es.wikipedia.org/wiki/Usuario:Andreuvv" target="_blank"
                        rel="noopener noreferrer">Andreuvv</a> is licensed under <a
                        href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank"
                        rel="noopener noreferrer">CC-BY-SA 4.0 license</a>.
                        <br/>
                        <a href="https://commons.wikimedia.org/wiki/File:Plate_clip_art.png" target="_blank"
                           rel="noopener noreferrer">Plate clip art</a> made by <a href="https://smart.servier.com"
                                                                                   target="_blank"
                                                                                   rel="noopener noreferrer">SMART
                        Servier Medical Art</a> is licensed under <a
                        href="https://creativecommons.org/licenses/by-sa/3.0/deed.en" target="_blank"
                        rel="noopener noreferrer">CC BY-SA 3.0 license</a>.
                        <br/>
                        <a href="https://www.vecteezy.com/vector-art/594214-blue-abstract-comic-cartoon-sunlight-background-vector-illustration-design"
                           target="_blank" rel="noopener noreferrer">Blue Abstract Comic Cartoon Sunlight
                            Background</a> obtained from <a href="https://www.vecteezy.com/free-vector/vector">Vector
                        Vectors by Vecteezy</a>.
                        <br/>
                        <a href="https://www.freepik.com/free-vector/comic-style-background-flat-design_11630069.htm#page=1&query=Comic%20background&position=25"
                           target="_blank" rel="noopener noreferrer">Comic style background in flat design Free
                            Vector</a> obtained from <a href="https://www.freepik.com/vectors/background">Freepik -
                        www.freepik.com</a>.
                        <br/>
                        <a href="https://www.canstockphoto.com/simple-cartoon-style-world-map-86575387.html"
                           target="_blank" rel="noopener noreferrer">Simple Cartoon Style World Map</a> obtained from <a
                        href="https://www.canstockphoto.com">(c) Can Stock Photo / Sudowoodo</a>.
                        <br/>
                        Pokèmon and related trademarks are owned by Nintendo, Game Freak, Creatures Inc.
                        <br/>
                        Simspsons and related trademarks are owned by Twentieth Century Fox Film Corporation.
                        <br/>
                        Hearthstone and related trademarks are owned by Activision Blizzard, Inc.
                        <br/>
                        League of Legends and related trademarks are owned by Riot Games.
                        <br/>
                        <br/>
                        <Title level={4}>Sound effects</Title>
                        <a href="https://freesound.org/people/SunnySideSound/sounds/67093/#" target="_blank"
                           rel="noopener noreferrer">Pop_7.aif</a> made by <a
                        href="https://freesound.org/people/SunnySideSound/" target="_blank"
                        rel="noopener noreferrer">SunnySideSound</a> is licensed under <a
                        href="https://creativecommons.org/licenses/by/3.0/" target="_blank" rel="noopener noreferrer">CC-BY
                        3.0 license</a>.
                        <br/>
                        <a href="https://www.freesoundslibrary.com/tada-sound/" target="_blank"
                           rel="noopener noreferrer">Tada Sound</a> made by Spanac is licensed under <a
                        href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank"
                        rel="noopener noreferrer">CC-BY-SA 4.0 license</a>.
                        <br/>
                        <a href="https://www.zapsplat.com/music/balloon-pop/" target="_blank" rel="noopener noreferrer">Balloon
                            pop</a> and <a
                        href="https://www.zapsplat.com/music/bright-tuned-metal-mallet-notification-tone-chime-hit-version-4/"
                        target="_blank" rel="noopener noreferrer">Bright tuned metal mallet, notification tone, chime,
                        hit. Version 4</a> obtained from <a href="https://www.zapsplat.com" target="_blank"
                                                            rel="noopener noreferrer">Zapsplat.com</a>.
                        <br/>
                    </Paragraph>
                    <Title level={3}>Thank you!</Title>
                    <Paragraph>
                        Please direct any questions you have to <a href="mailto:driedbeans@livebingo.app"
                                                                   target="_blank"
                                                                   rel="noopener noreferrer">driedbeans@livebingo.app</a>.
                        Your dearest driest beans.
                    </Paragraph>
                </div>
            </div>
            <Footer/>
        </div>
    )
}