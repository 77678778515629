import React from 'react'
import { notification } from 'antd'

import GiftIcon from '../img/icons/gift.png'
import TicketsIcon from '../img/icons/tickets.png'
import AudioIcon from '../img/icons/audio.png'
import BitIcon from '../img/icons/bit.png'

import i18n from '../i18n/i18n'

export const showCardsNotification = (purchase) => {
    notification.open({
        message: <div style={{ fontSize: 30, fontWeight: 'bold' }}>{purchase.display_name}</div>,
        icon: <img src={TicketsIcon} height="40" width="40" alt="tickets" />,
        closeIcon: <></>,
        btn: <div
            style={{ border: '1px #00000033 solid', borderRadius: 10, paddingLeft: 8, paddingRight: 8 }}>
            <b>{purchase.bits_spent}</b><img style={{ marginBottom: 5 }} src={BitIcon} width="25" height="25"
                alt="Bit" />
        </div>,
        description: <div style={{
            fontSize: 25,
            lineHeight: 1
        }}>{purchase.cards_purchased > 1 ? i18n.t('livescoreboard.has_unlocked_x_cards', { num: purchase.cards_purchased }) : i18n.t('livescoreboard.has_unlocked_1_card')}</div>,
        duration: 3
    })
}

export const showAudioNotification = (displayName, audio) => {
    notification.open({
        message: <div style={{ fontSize: 30, fontWeight: 'bold' }}>{displayName}</div>,
        icon: <img src={AudioIcon} height="40" width="40" alt="audio" />,
        closeIcon: <></>,
        btn: <div style={{ border: '1px #00000033 solid', borderRadius: 10, paddingLeft: 8, paddingRight: 8 }}>
            <b>{audio.price}</b><img style={{ marginBottom: 5 }} src={BitIcon} width="25" height="25" alt="Bit" />
        </div>,
        description: <div style={{
            fontSize: 25,
            lineHeight: 1
        }}>{i18n.t('livescoreboard.has_played')}: &quot;{i18n.t(`audios.${audio.audio_id}`)}&quot;</div>,
        duration: 5
    })
}

export const showGiftNotification = (purchase) => {
    notification.open({
        message: <div style={{ fontSize: 30, fontWeight: 'bold' }}>{purchase.display_name}</div>,
        icon: <img src={GiftIcon} height="40" width="40" alt="tickets" />,
        closeIcon: <></>,
        btn: <div
            style={{ border: '1px #00000033 solid', borderRadius: 10, paddingLeft: 8, paddingRight: 8 }}>
            <b>{purchase.bits_spent}</b><img style={{ marginBottom: 5 }} src={BitIcon} width="25" height="25"
                                             alt="Bit" />
        </div>,
        description: <div style={{
            fontSize: 25,
            lineHeight: 1
        }}>{purchase.cards_purchased > 1 ? i18n.t('livescoreboard.has_gifted_x_cards', { num: purchase.cards_purchased }) : i18n.t('livescoreboard.has_gifted_1_card')}</div>,
        duration: 3
    })
}
