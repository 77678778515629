import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import './LiveScoreboard.css'
import { Row, Col, Statistic, Spin, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {
    ALL_NAMES,
    PATTERNS_ARRAYS,
    BINGO_THEMES,
    BINGO_MODES,
    BINGO_PHASES,
    BINGO_FLAGS,
    getNumberLetter,
    SPOLIGHT_TYPE,
    CUSTOM_THEME, getPrizeQuantity, handleImageError
} from './util/Utils'
import InstructionGif from './gif/get-obs-url.gif'
import Confetti from 'react-dom-confetti'
import { io } from 'socket.io-client'
import RandomNumber from './RandomNumber'
import { Provider, useDispatch, useSelector } from 'react-redux'
import store from './redux/store'
import {
    callBingoSuccess,
    callLineSuccess,
    cancelRandomSuccess,
    createBingoSuccess,
    declareBingoWinnersSuccess,
    declareLineWinnersSuccess,
    drawnNumbersSuccess,
    endBingoSuccess,
    loadScoreboard,
    loopSpotlight,
    retryActivityFeedSuccess,
    showCustomTheme,
    startBingoSuccess,
    startRandomBingoWinnerSuccess,
    startRandomLineWinnerSuccess,
    startRandomNumberSuccess,
    updateEnabledSoundsSuccess,
    updateLanguageSuccess,
    updateStartingDateSuccess,
    updateTitleSuccess
} from './redux/scoreboardSlice'
import i18n from './i18n/i18n'
import {
    loopDrumrollSound,
    playAudio,
    playBingoAudio,
    playBuySound,
    playGiftSound,
    stopDrumrollSound
} from './util/Audio'
import { useParams } from 'react-router-dom'
import 'antd/dist/antd.min.css'
import { showAudioNotification, showCardsNotification, showGiftNotification } from './util/Notification'
import { AnimatePresence, motion } from 'framer-motion'
import CustomThemeScoreboard from './CustomThemeScoreboard'
import BeanIcon from './img/symbols/CLASSIC.png'

const { Countdown } = Statistic

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL

function Square(props) {
    let number
    let name

    if (props.theme === BINGO_THEMES.CLASSIC) {
        number = <div className={'number-text ' + (props.called ? 'number-text-called' : '')}>{props.value}</div>
    } else {
        name = props.customThemeNumber ? props.customThemeNumber.n : ALL_NAMES[props.theme][props.flag][props.value - 1]
        number = <img className={'number-img ' + (props.called ? 'number-img-called' : '')}
                      src={props.customThemeNumber ? props.customThemeNumber.img : require(`./img/numbers/${props.theme}/${name}.png`)}
                      alt="number" loading="lazy"
                      onError={(e) => {
                          if (props.customThemeNumber) handleImageError(e.target, props.customThemeNumber.img)
                      }
                      }/>
    }

    return (
        <Col style={{ width: props.flag === BINGO_FLAGS.US ? '6.666%' : '10%' }}>
            <div className={'col-img-container ' + (props.called ? 'col-img-container-called ' : '')}>
                {number}
            </div>
        </Col>
    )
}

function renderSquare(number, bingo) {
    const customThemeNumber = bingo.custom_theme?.numbers[number]
    /*let isLastNumber = false
    if (bingo.drawn_numbers) {
        isLastNumber = bingo.drawn_numbers[bingo.drawn_numbers.length - 1] === number
    }*/

    return (
        <Square
            key={bingo.theme + '' + number}
            value={number}
            flag={bingo.flag}
            called={bingo.drawn_numbers.includes(number)}
            theme={bingo.theme}
            customThemeNumber={customThemeNumber}
            //isRandomNumber={randomNumber === number}
        />
    )
}

function LiveScoreboard() {

    const broadcaster = useSelector(state => state.scoreboard.broadcaster)
    const bingo = useSelector(state => state.scoreboard.bingo)
    const linePeople = useSelector(state => state.scoreboard.linePeople)
    const bingoPeople = useSelector(state => state.scoreboard.bingoPeople)
    const lineWinners = useSelector(state => state.scoreboard.lineWinners)
    const bingoWinners = useSelector(state => state.scoreboard.bingoWinners)
    const spotlightType = useSelector(state => state.scoreboard.spotlightType)
    const startRandom = useSelector(state => state.scoreboard.startRandom)
    const finishedLoading = useSelector(state => state.scoreboard.finishedLoading)
    const wrongBroadcasterId = useSelector(state => state.scoreboard.wrongBroadcasterId)
    const customThemeShowed = useSelector(state => state.scoreboard.customThemeShowed)
    const randomNumber = useSelector(state => state.scoreboard.randomNumber)

    const [fakeBingoAnimation, setFakeBingoAnimation] = useState(false)
    const [titleAnimation, setTitleAnimation] = useState(false)
    const [remainingTime, setRemainingTime] = useState(0) //Serve per fare il render quando finisce il countdown

    const customThemeShowedTimeoutId = useRef(null)
    const params = useParams()
    const dispatch = useDispatch()

    //importa il css del rispettivo tema del bingo
    useEffect(() => {
        if (bingo.custom_theme) require(`./themes-css/${CUSTOM_THEME}.css`)
        else if (bingo.theme) require(`./themes-css/${bingo.theme}.css`)
    }, [bingo])

    //Aggiorna il countdown quando bingo.starting_date cambia
    useEffect(() => {
        setRemainingTime(moment(bingo.starting_date).diff(moment(), 'seconds'))
    }, [bingo.starting_date])

    //Avvia i tamburi se abilitati dallo streamer quando parte startRandomWinner
    useEffect(() => {
        if ((spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE || spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE) &&
            startRandom && broadcaster.enabled_sounds.winners) {
            loopDrumrollSound()
        }

        return () => {
            stopDrumrollSound()
        }
    }, [broadcaster.enabled_sounds, startRandom, spotlightType])

    useEffect(() => {
        let timeoutId
        if (titleAnimation) {
            setFakeBingoAnimation(false)
            timeoutId = setTimeout(() => {
                setTitleAnimation(false)
            }, 2000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [titleAnimation])

    useEffect(() => {
        let timeoutId
        if (fakeBingoAnimation) {
            timeoutId = setTimeout(() => {
                setFakeBingoAnimation(false)
            }, 2000)
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [fakeBingoAnimation])

    useEffect(() => {
        const intervalId = dispatch(loopSpotlight())

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, [dispatch, startRandom, spotlightType, bingoPeople, linePeople]) //bingoPeople, linePeople servono per far ripartire il loop quando ci sono altri bingo o line

    useEffect(() => {
        let socket
        if (finishedLoading) {
            socket = io(WEBSOCKET_URL, {
                query: {
                    broadcaster_id: broadcaster.broadcaster_id
                },
                transports: ['websocket']
            })
            socket.onAny((eventName, value) => {
                //console.log(eventName, value)

                if (eventName === 'CREATE_BINGO') {
                    dispatch(createBingoSuccess(value))
                } else if (eventName === 'DRAWN_NUMBERS') {
                    dispatch(drawnNumbersSuccess(value))
                } else if (eventName === 'BINGO') {
                    setTitleAnimation(true)
                    dispatch(callBingoSuccess(value))
                } else if (eventName === 'LINE') {
                    setTitleAnimation(true)
                    dispatch(callLineSuccess(value))
                } else if (eventName === 'START_RANDOM_NUMBER') {
                    dispatch(startRandomNumberSuccess())
                } else if (eventName === 'START_RANDOM_WINNER_LINE') {
                    dispatch(startRandomLineWinnerSuccess(value))
                } else if (eventName === 'START_RANDOM_WINNER_BINGO') {
                    dispatch(startRandomBingoWinnerSuccess(value))
                } else if (eventName === 'DECLARE_BINGO_WINNERS') {
                    dispatch(declareBingoWinnersSuccess(value))
                } else if (eventName === 'DECLARE_LINE_WINNERS') {
                    dispatch(declareLineWinnersSuccess(value))
                } else if (eventName === 'CANCEL_RANDOM') {
                    dispatch(cancelRandomSuccess())
                } else if (eventName === 'START_BINGO') {
                    dispatch(startBingoSuccess())
                } else if (eventName === 'UPDATE_TITLE') {
                    dispatch(updateTitleSuccess(value))
                } else if (eventName === 'UPDATE_STARTING_DATE') {
                    dispatch(updateStartingDateSuccess(value))
                } else if (eventName === 'END_BINGO') {
                    dispatch(endBingoSuccess())
                } else if (eventName === 'UPDATE_LANGUAGE') {
                    dispatch(updateLanguageSuccess(value))
                } else if (eventName === 'UPDATE_ENABLED_SOUNDS') {
                    dispatch(updateEnabledSoundsSuccess(value))
                } else if (eventName === 'RETRY_ACTIVITY_FEED') {
                    dispatch(retryActivityFeedSuccess(value))
                } else if (eventName === 'BUY_CARDS') {
                    if (broadcaster.enabled_sounds.cards) {
                        playBuySound()
                    }
                    showCardsNotification(value)
                } else if (eventName === 'BUY_AUDIO') {
                    if (value.audio.audio_id === 'fake_bingo') {
                        playBingoAudio()
                        setFakeBingoAnimation(true)
                    } else {
                        playAudio(require(`./audio/buy/${value.audio.audio_id}.mp3`))
                    }
                    showAudioNotification(value.display_name, value.audio)
                } else if (eventName === 'BUY_GIFT') {
                    if (broadcaster.enabled_sounds.cards) {
                        playGiftSound()
                    }
                    showGiftNotification(value)
                } else if (eventName === 'TEST_AUDIO') {
                    if (value.audio_id === 'fake_bingo') {
                        playBingoAudio()
                    } else {
                        playAudio(require(`./audio/buy/${value.audio_id}.mp3`))
                    }
                    showAudioNotification(i18n.t('livescoreboard.someone'), value)
                } else if (eventName === 'SHOW_CUSTOM_THEME') {
                    if (customThemeShowedTimeoutId.current) {
                        clearTimeout(customThemeShowedTimeoutId.current)
                    }
                    customThemeShowedTimeoutId.current = dispatch(showCustomTheme(value))
                }
            })
        }
        return () => {
            if (broadcaster && socket) {
                socket.disconnect()
            }
        }
    }, [broadcaster, finishedLoading, dispatch])

    useEffect(() => {
        dispatch(loadScoreboard(params.broadcaster_id_encrypted))
    }, [dispatch, params])

    if (wrongBroadcasterId) {
        return (<div style={{
            textAlign: 'center',
            padding: 30,
            fontSize: 30,
            width: 1920,
            height: 1080,
            background: '#262626'
        }}>
            <div style={{ color: '#fff', marginTop: 20, marginBottom: 50 }}>{i18n.t('livescoreboard.wrong_url')}</div>
            <img src={InstructionGif} alt="get-obs-url" width="1000"/>
        </div>)
    } else if (!finishedLoading) {
        return (<div style={{
            textAlign: 'center',
            padding: 30,
            margin: 20
        }}><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin/>}/></div>)
    }

    let number
    let name
    if (bingo.theme === BINGO_THEMES.CLASSIC) {
        number = <span className={`ball-number ball-number-${bingo.flag}`}>{randomNumber > 0 ? randomNumber : ''}</span>
    } else {
        if (randomNumber) {
            const customThemeNumber = bingo.custom_theme?.numbers[randomNumber]
            name = customThemeNumber ? customThemeNumber.n : ALL_NAMES[bingo.theme][bingo.flag][randomNumber - 1]
            number =
                <img className={`absolute random-img-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}
                     src={customThemeNumber ? customThemeNumber.img : require(`./img/numbers/${bingo.theme}/${name}.png`)}
                     alt="random" loading="lazy"/>
        }
    }

    const hideContainer = randomNumber === 0 && spotlightType === SPOLIGHT_TYPE.NUMBERS

    return (
        <div style={{ position: 'relative', width: 1920, height: 1080 }}>
            {
                //Se il bingo non esiste o è OVER mostra 'nessun bingo in corso', sennò se è in WAITING mostra il titolo e il timer (tutto sopra il tabellone)
                !bingo.phase || bingo.phase === BINGO_PHASES.OVER ?
                    <div className="big-text-center">{i18n.t('livescoreboard.bingo_not_running')}</div>
                    : bingo.phase === BINGO_PHASES.WAITING &&
                    <>
                        <div className="big-text-title">{bingo.title}</div>
                        <div className="big-text-timer">
                            <div className="big-text-timer-inner">
                                {
                                    remainingTime > 0 ?
                                        <Countdown title="" format={remainingTime >= 3600 ? 'HH:mm:ss' : 'mm:ss'}
                                                   value={bingo.starting_date}
                                                   onFinish={() => setRemainingTime(0)}/> :
                                        i18n.t('livescoreboard.starting_soon')
                                }
                            </div>
                        </div>
                        {bingo.extra_prize &&
                            <div className="big-text-extra-prize">
                                <Space align="center" size={30}>
                                    <div>🥇</div>
                                    <Space align="center">{getPrizeQuantity(bingo.extra_prize)}×<img
                                        src={require(`./img/viewer-attack/${bingo.extra_prize.toLowerCase()}.png`)}
                                        height={120} width={120} alt={bingo.extra_prize}/>
                                    </Space>
                                </Space>
                            </div>
                        }
                    </>
            }
            <div
                className={`background-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}` + (bingo.phase !== BINGO_PHASES.PLAYING && bingo.phase !== BINGO_PHASES.COMPLETED ? ' blur-effect' : '')}
                style={bingo.custom_theme?.background ? { width: '100%', height: '100%', backgroundImage: `url(${bingo.custom_theme.background})`, backgroundSize: '1920px 1080px' } : { width: '100%', height: '100%' }}>
                <div
                    className={`numbers-count-container numbers-count-container-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                    <div
                        style={{ position: 'absolute', top: 24, fontSize: 50, left: 86, transform: 'translateX(-50%)' }}>
                        {bingo.drawn_numbers.length}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 12, paddingLeft: 150 }}>
                        <AnimatePresence>
                            {
                                //Map last 5 drawn_numbers
                                bingo.drawn_numbers.slice(-5).map((number) => {
                                    const customThemeNumber = bingo.custom_theme?.numbers[number]
                                    const times = 6 - bingo.drawn_numbers.slice(-5).length
                                    return (
                                        <motion.div key={number} layout
                                                    className={`old-number-drawn-container ${bingo.theme === BINGO_THEMES.CLASSIC ?
                                                        `ball-${bingo.flag === BINGO_FLAGS.EU ? 'N' : getNumberLetter(number)}` : ''
                                                    }`}
                                                    exit={{ opacity: 0, transition: { ease: 'easeInOut', duration: 0.25 } }}
                                                    transition={{ duration: 1.5 * times }}
                                                    initial={{ opacity: 0, rotate: 360 * times, x: 112 * times }}
                                                    animate={{ scale: 1, opacity: 1, rotate: 0, x: 0 }}>
                                            {
                                                bingo.theme === BINGO_THEMES.CLASSIC ?
                                                    <div className="ball-small">
                                                        <span>{number}</span>
                                                    </div> :
                                                    <img
                                                        src={customThemeNumber ? customThemeNumber.img : require(`./img/numbers/${bingo.theme}/${ALL_NAMES[bingo.theme][bingo.flag][number - 1]}.png`)}
                                                        alt="old-number" loading="lazy"
                                                        onError={(e) => {
                                                            if (customThemeNumber) handleImageError(e.target, customThemeNumber.img)
                                                        }}/>
                                            }
                                        </motion.div>
                                    )
                                })
                            }
                        </AnimatePresence>
                    </div>
                </div>
                <div
                    className={`absolute random-container-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                    {bingo.theme === BINGO_THEMES.CLASSIC ?
                        hideContainer ?
                            <div className="ball-nothing"></div> :
                            <div
                                className={`ball ${bingo.flag === BINGO_FLAGS.US && bingoPeople.length === 0 && bingoWinners.length === 0 ? `ball-${getNumberLetter(randomNumber)}` : ''}`}>
                                {(bingo.flag === BINGO_FLAGS.US && bingoPeople.length === 0 && bingoWinners.length === 0) &&
                                    <span className="ball-letter">
                                        {getNumberLetter(randomNumber)}
                                    </span>}
                                <div className="ball-number-border">
                                    <div className="ball-number-background">
                                        <RandomNumber
                                            randomNumber={randomNumber}
                                            isCustomTheme={Boolean(bingo.custom_theme)}
                                            flag={bingo.flag}
                                            theme={bingo.theme}
                                            spotlightType={spotlightType}
                                            linePeople={linePeople}
                                            bingoPeople={bingoPeople}
                                            lineWinners={lineWinners}
                                            bingoWinners={bingoWinners}
                                            number={number}
                                        />
                                    </div>
                                </div>
                            </div>
                        :
                        <>
                            <div
                                className={`absolute numbers-background-img numbers-background-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme} ${hideContainer && bingo.theme === BINGO_THEMES.SOCCER_PLAYERS ? 'display-none' : ''}`}/>
                            <div
                                className={`absolute numbers-overground-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme} ${hideContainer && bingo.theme === BINGO_THEMES.SOCCER_PLAYERS ? 'display-none' : ''}`}/>
                            <RandomNumber
                                randomNumber={randomNumber}
                                isCustomTheme={Boolean(bingo.custom_theme)}
                                flag={bingo.flag}
                                theme={bingo.theme}
                                spotlightType={spotlightType}
                                linePeople={linePeople}
                                bingoPeople={bingoPeople}
                                lineWinners={lineWinners}
                                bingoWinners={bingoWinners}
                                number={number}
                            />
                        </>
                    }
                </div>

                {/* Per il Fake Bingo */}
                <AnimatePresence>
                    {fakeBingoAnimation &&
                        <motion.div
                            exit={{ opacity: 0, scale: 0 }}
                            className={`title-container title-container-animation title-container-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                            {i18n.t('livescoreboard.bingo')}
                        </motion.div>
                    }
                </AnimatePresence>

                {
                    //Stampa il nome e l'immagine dei vincitori, in caso contrario se è uscito un numero stampa il suo nome
                    spotlightType !== SPOLIGHT_TYPE.NUMBERS ?
                        <>
                            <div
                                className={`random-name random-name-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                                <div
                                    className={`random-name-inner random-name-inner-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                                    {
                                        spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE ? linePeople[randomNumber]?.display_name :
                                            spotlightType === SPOLIGHT_TYPE.LINE_WINNERS ? lineWinners[randomNumber]?.display_name :
                                                spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE ? bingoPeople[randomNumber]?.display_name :
                                                    bingoWinners[randomNumber]?.display_name
                                    }
                                </div>
                            </div>

                            <div
                                className={`title-container ${titleAnimation ? 'title-container-animation' : ''} title-container-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme} ${(startRandom && bingo.flag === BINGO_FLAGS.EU) ? 'title-container-smaller' : ''}`}>
                                {
                                    spotlightType === SPOLIGHT_TYPE.BINGO_WINNERS ? (bingoWinners.length > 1 ? i18n.t('livescoreboard.winners') : i18n.t('livescoreboard.winner')) :
                                        spotlightType === SPOLIGHT_TYPE.LINE_WINNERS ? (lineWinners.length > 1 ? i18n.t('livescoreboard.line_winners') : i18n.t('livescoreboard.line_winner')) :
                                            startRandom ? i18n.t('livescoreboard.winner_is') :
                                                spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE ? i18n.t('livescoreboard.bingo') :
                                                    i18n.t('livescoreboard.line')
                                }
                            </div>
                        </> : Boolean(name) &&
                        <div
                            className={`random-name random-name-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                            <div
                                className={`random-name-inner random-name-inner-${bingo.flag}-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                                {bingo.custom_theme ? name : i18n.t(`${bingo.theme}.${name}`)}
                            </div>
                        </div>
                }

                {
                    //Se il bingo è americano, stampa le lettere 'BINGO' a sinistra delle file dei numeri
                    bingo.flag === BINGO_FLAGS.US &&
                    <div className="bingo-letters-container">
                        {['B', 'I', 'N', 'G', 'O'].map((it, index) => {
                            return (
                                <Row style={{ paddingBottom: 16 }} key={'letter' + index}>
                                    <Col>
                                        <div className="bingo-letter-square">
                                            <div className="bingo-letter">{it}</div>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                }

                <div style={{ position: 'absolute', left: 960, top: 0, zIndex: 5000 }}>
                    <Confetti
                        active={spotlightType === SPOLIGHT_TYPE.LINE_WINNERS || spotlightType === SPOLIGHT_TYPE.BINGO_WINNERS}
                        config={{
                            angle: 90,
                            spread: 360,
                            startVelocity: 40,
                            elementCount: '200',
                            dragFriction: 0.12,
                            duration: 5000,
                            stagger: 3,
                            width: '10px',
                            height: '10px',
                            perspective: '500px',
                            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
                        }}/>
                </div>

                {
                    //Se è presente, stampa il pattern in alto a destra
                    bingo.mode === BINGO_MODES.US_PATTERN &&
                    <div className="pattern-background">
                        <div
                            className={`pattern-internal pattern-internal-${bingo.custom_theme ? CUSTOM_THEME : bingo.theme}`}>
                            {PATTERNS_ARRAYS[bingo.pattern[1]].map((row, index) => {
                                return (
                                    <Row justify="center" key={'row' + index}>
                                        {row.map((value, index) => {
                                            return (
                                                <Col style={{ width: '20%', padding: 3 }} key={'col' + index}>
                                                    <div className={'pattern-square'}>
                                                        {value === 1 ? <img className="sign-img"
                                                                            src={bingo.custom_theme ? (bingo.custom_theme.marker ?? BeanIcon) : require(`./img/symbols/${bingo.theme}.png`)}
                                                                            alt={'sign-' + index}
                                                                            loading="lazy"/> : <></>}
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                )
                            })}
                        </div>
                    </div>
                }

                <div className={`scoreboard-${bingo.flag}`} style={{ position: 'absolute' }}>
                    {[...Array(bingo.rows).keys()].map((_, rowIndex) => {
                        const start = rowIndex * bingo.cols
                        return (
                            <Row gutter={16} style={{ paddingBottom: 16 }} justify="center" key={rowIndex}>
                                {[...Array(bingo.cols).keys()].map((_, numIndex) => {
                                    const number = start + numIndex
                                    return renderSquare(number + 1, bingo)
                                })}
                            </Row>
                        )
                    })}
                </div>
            </div>

            <AnimatePresence>
                {
                    Boolean(customThemeShowed) &&
                    <CustomThemeScoreboard customTheme={customThemeShowed}/>
                }
            </AnimatePresence>


        </div>
    )
}


export default function LiveScoreboardRedux() {
    return (
        <Provider store={store}>
            <LiveScoreboard/>
        </Provider>
    )
}