import React from 'react'
import './App.css'
import TermsOfServicePage from './TermsOfServicePage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import TutorialPage from './TutorialPage'
import HomePage from './HomePage'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LiveScoreboardRedux from './LiveScoreboard'

export default function App() {
    return (
        <section className="App">
            <Router>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/scoreboard/:broadcaster_id_encrypted" element={<LiveScoreboardRedux />} />
                    <Route exact path="/termsofservice" element={<TermsOfServicePage />} />
                    <Route exact path="/tutorial" element={<TutorialPage />} />
                    <Route exact path="/privacypolicy" element={<PrivacyPolicyPage />} />
                </Routes>
            </Router>
        </section>
    )
}