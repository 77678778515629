import React from 'react'
import { CUSTOM_THEME, SPOLIGHT_TYPE } from './util/Utils'

export default function RandomNumber(props) {
    //NB. se randomNumber se è 0 allora significa che non è stato estratto nessun numero
    return (
        <>
            {
                props.spotlightType !== SPOLIGHT_TYPE.NUMBERS ?
                    <>
                        <img
                            className={`absolute profile-image-${props.flag}-${props.isCustomTheme ? CUSTOM_THEME : props.theme} random-img-${props.flag}-${props.isCustomTheme ? CUSTOM_THEME : props.theme}`}
                            style={{ borderRadius: '50%' }}
                            src={
                                props.spotlightType === SPOLIGHT_TYPE.LINE_PEOPLE ? props.linePeople[props.randomNumber]?.profile_image_url :
                                    props.spotlightType === SPOLIGHT_TYPE.LINE_WINNERS ? props.lineWinners[props.randomNumber]?.profile_image_url :
                                        props.spotlightType === SPOLIGHT_TYPE.BINGO_PEOPLE ? props.bingoPeople[props.randomNumber]?.profile_image_url :
                                            props.bingoWinners[props.randomNumber]?.profile_image_url
                            } alt="random" loading="lazy" />
                    </>
                    : props.randomNumber > 0 &&
                    <>
                        {props.number}
                    </>
            }
        </>
    )

}