import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import twitchIcon from './img/icons/twitch_white_logo.png'
import broadcasterIcon from './img/icons/broadcaster.png'
import viewersIcon from './img/icons/viewers.png'
import bitsIcon from './img/icons/bits.png'
import Cards from './img/icons/cards.png'
import ThemesIcon from './img/icons/themes.png'
import BitIcon from './img/icons/bit-black.png'
import StarIcon from './img/icons/star.png'
import CardsThemes from './img/icons/cards-themes.png'
import Background from './img/homepage/bg_hero_1.svg'
import Background2 from './img/homepage/pattern_1.svg'

export default function HomePage() {

    return (
        <div className="HomePage">

            <Navbar />

            <div className="page-hero-section bg-image hero-home-1" style={{ backgroundImage: `url(${Background})` }}>
                <div className="hero-caption pt-5">
                    <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-lg-6 wow fadeInUp">
                                <div className="mb-1" style={{ fontSize: '2.3vh' }}>Top 5 Twitch Games in Extensions</div>
                                <h1 className="mb-1" style={{ fontSize: '7vh', fontWeight: 'bold' }}>LIVE BINGO</h1>
                                <p className="mb-4" style={{ fontSize: '3vh', color: '#4e4a4a', lineHeight: '3vh' }}>Host a game of Bingo on your Twitch channel and let your viewers play!</p>
                                <a href="https://dashboard.twitch.tv/extensions/e6poixtnadaxyvt9pkm5upiy7jn5uv" target="_blank" rel="noopener noreferrer" className="btn btn-primary rounded-pill">
                                    <img src={twitchIcon} className="twitch-icon" alt="twitch" /> Install Live Bingo
                                </a>
                            </div>
                            <div className="col-lg-6 d-none d-lg-block wow fadeInUp">
                                <div className="img-place cards-preview floating-animate">
                                    <img src={Cards} alt="cards" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="position-realive bg-image" style={{ backgroundImage: `url(${Background2})` }}>
                <div className="page-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 py-3">
                                <div className="img-place cards-themes-preview vertical-center wow zoomIn p-1">
                                    <img className="floating-animate" src={CardsThemes} alt="cards-themes" />
                                </div>
                            </div>
                            <div className="col-lg-6 py-3 mt-lg-5">
                                <div className="iconic-list">
                                    <div className="iconic-item wow fadeInUp">
                                        <div className="iconic-md iconic-text bg-warning fg-white rounded-circle">
                                            <img src={ThemesIcon} alt="theme" style={{ height: 50, width: 50, marginLeft: 4, marginBottom: 4 }} />
                                        </div>
                                        <div className="iconic-content">
                                            <h5>CHOOSE YOUR BINGO THEME</h5>
                                            <p className="fs-small">You can select between many different themes in Live Bingo. Choose the theme that better suits your stream or alternate them every game to give a different experience to your community every time!</p>
                                        </div>
                                    </div>
                                    <div className="iconic-item wow fadeInUp">
                                        <div className="iconic-md iconic-text bg-info fg-white rounded-circle">
                                            <img src={StarIcon} alt="theme" style={{ height: 48, width: 48 }} />
                                        </div>
                                        <div className="iconic-content">
                                            <h5>HOST EXCLUSIVE GAMES FOR SUBS</h5>
                                            <p className="fs-small">Gift free cards to your subs and use Live Bingo for giveaways and original content you have in mind!</p>
                                        </div>
                                    </div>
                                    <div className="iconic-item wow fadeInUp">
                                        <div className="iconic-md iconic-text bg-indigo fg-white rounded-circle">
                                            <img src={BitIcon} alt="theme" style={{ height: 50, width: 50 }} />
                                        </div>
                                        <div className="iconic-content">
                                            <h5>EARN MORE BITS</h5>
                                            <p className="fs-small">Increase your stream income by giving your viewers an incentive to use Bits in your stream. WIth Live Bingo your viewers can buy extra cards each game and play funny audios with Bits!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt-lg-5 wow fadeInUp">
                                <h1 className="mb-4 text-center"></h1>
                                <div className='embed-container'>
                                    <iframe src="https://www.youtube.com/embed/Gw73jOmPpQ8?si=yhAxKZrg1aFXRybO" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-section bg-dark fg-white" style={{ paddingTop: 50, paddingBottom: 50 }}>
                    <div className="container">
                        <h1 className="text-center text-white">Live Bingo Stats</h1>

                        <div className="row justify-content-center mt-5">
                            <div className="col-md-6 col-lg-3 py-3">
                                <div className="card card-body border-0 bg-transparent text-center wow zoomIn">
                                    <div className="mb-3">
                                        <img src={broadcasterIcon} alt="broadcaster" height="100" />
                                    </div>
                                    <div style={{ fontSize: 50, fontWeight: 'bold' }}>+10K</div>
                                    <p className="fs-small fg-grey">ACTIVE STREAMERS</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 py-3">
                                <div className="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="400ms">
                                    <div className="mb-3">
                                        <img src={viewersIcon} alt="viewers" height="100" />
                                    </div>
                                    <div style={{ fontSize: 50, fontWeight: 'bold' }}>+10M</div>
                                    <p className="fs-small fg-grey">UNIQUE VIEWERS</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3 py-3">
                                <div className="card card-body border-0 bg-transparent text-center wow zoomIn" data-wow-delay="600ms">
                                    <div className="mb-3">
                                        <img src={bitsIcon} alt="bits" height="100" />
                                    </div>
                                    <div style={{ fontSize: 50, fontWeight: 'bold' }}>+10M</div>
                                    <p className="fs-small fg-grey">BITS USED</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </div>
    )
}