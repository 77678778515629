import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import OverlayGif from './img/faq/overlay.gif'
import ComponentGif from './img/faq/component.gif'
import MobileGif from './img/faq/mobile.gif'
import Tutorial1 from './img/tutorial/tutorial_1.png'
import Tutorial2 from './img/tutorial/tutorial_2_1.gif'
import Tutorial2Component from './img/tutorial/tutorial_2_component.png'
import Tutorial2Overlay from './img/tutorial/tutorial_2_overlay.png'
import Tutorial3 from './img/tutorial/tutorial_3.gif'
import Tutorial4Config from './img/tutorial/tutorial_4_config.gif'
import Tutorial4Obs from './img/tutorial/tutorial_4_obs.gif'
import Tutorial51 from './img/tutorial/tutorial_5_1.png'
import Tutorial52 from './img/tutorial/tutorial_5_2.png'
import Tutorial53 from './img/tutorial/tutorial_5_3.png'
import Tutorial54 from './img/tutorial/tutorial_5_4.png'
import { Collapse, Divider, Typography } from 'antd'
const { Panel } = Collapse
const { Title, Paragraph } = Typography

export default function TutorialPage() {

    return (
        <div className="TutorialPage">

            <Navbar />

            <div className="page-section" style={{ paddingTop: 170 }} >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="card-page">
                                <h5 className="fg-primary">Tutorial</h5>
                                <hr />
                                <Paragraph>Live Bingo is a Twitch extension that allows streamers to host live games of Bingo during streams letting their viewers play all together.<br />Here below you will find a complete tutorial for installing the extension on your channel.</Paragraph>

                                <Title level={4} style={{ textAlign: 'center' }}>Install Live Bingo from the Twitch Extension Store</Title>
                                <Paragraph>Once on the Live Bingo Extension Details Page, tap on the “+ Install” button.</Paragraph>
                                <img src={Tutorial1} alt="tutorial1" style={{ width: '100%', borderRadius: 10 }} />
                                <br />
                                <br />

                                <Title level={4} style={{ textAlign: 'center' }}>Activate the Extension</Title>
                                <Paragraph>Activate your extension by selecting the “Activate” drop down and selecting either the “Set as Component” or “Set As Overlay” option, as shown below. You’ll only be able to activate one option at a time, but you can always switch between modes whenever you like.</Paragraph>
                                <img src={Tutorial2} alt="tutorial2" style={{ width: '100%', borderRadius: 10 }} />
                                <br />
                                <br />
                                <Paragraph>Here you can find two examples of how your viewers will see Live Bingo if you have it installed as an Overlay or as a Component. We suggest setting it as an Overlay, which guarantees greater interactivity, allowing also your viewers to move cards where they prefer. You can change it every time you want, so select the option you prefer. </Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li>
                                            <p style={{ fontSize: 18 }}><b>Overlay example</b></p>
                                            <img src={Tutorial2Overlay} alt="tutorial2overlay" style={{ width: '100%', borderRadius: 10 }} />
                                        </li>
                                        <Divider />
                                        <li>
                                            <p style={{ fontSize: 18 }}><b>Component example</b></p>
                                            <img src={Tutorial2Component} alt="tutorial2component" style={{ width: '100%', borderRadius: 10 }} />
                                        </li>
                                    </ul>
                                </Paragraph>
                                <br />
                                <br />

                                <Title level={4} style={{ textAlign: 'center' }}>Extension Permissions</Title>
                                <Paragraph>Enable these authorizations to fully use all the extension features.</Paragraph>
                                <Paragraph>
                                    <ul>
                                        <li><b>Chat messages</b>: when your viewers unlock some extra bingo cards or play an audio they will get text feedback in your chat channel. This will help you earn some more Bits.</li>
                                        <li><b>Subscription Status</b>: enabling it will give you the possibility to gift a card to the viewers subscribed to your channel.</li>
                                        <li><b>Bits</b>: let your viewers use their Bits to unlock extra cards and play audios in your Live Bingo games.</li>
                                    </ul>
                                    <img src={Tutorial3} alt="tutorial3" style={{ width: '100%', borderRadius: 10 }} />
                                </Paragraph>
                                <br />
                                <br />

                                <Title level={4} style={{ textAlign: 'center' }}>Add the Live Bingo scoreboard on your OBS as a Browser Source</Title>
                                <Paragraph>This URL provides a cool overlay scoreboard to help your viewers tracking the drawn numbers, see what&apos;s the last extracted number and receive both visible and audible feedback when a player gets a bingo.</Paragraph>
                                <Paragraph>
                                    To set the scoreboard up:
                                    <ul>
                                        <li>Get your personal URL:</li>
                                        <br />
                                        <img src={Tutorial4Config} alt="tutorial4config" style={{ width: '100%', borderRadius: 10 }} />
                                        <br />
                                        <br />
                                        Then, on OBS Studio (or any other version of OBS):
                                        <br />
                                        <li>Click to Add Source -&gt; Browser Source;</li>
                                        <li>Paste URL, insert Width 1920 Height 1080;</li>
                                        <li>Click OK;</li>
                                        <li>Resize the scoreboard with the dimensions you prefer.</li>
                                        <br />
                                        <img src={Tutorial4Obs} alt="tutorial4obs" style={{ width: '100%', borderRadius: 10 }} />
                                        <br />
                                        <br />
                                    </ul>
                                </Paragraph>

                                <Title level={4} style={{ textAlign: 'center' }}>Start a game of Live Bingo</Title>
                                <Paragraph>After having set up Live Bingo you can find the extension in your Twitch channel dashboard.</Paragraph>
                                <Paragraph>Click the button there and you can open the Live Bingo dashboard.</Paragraph>
                                <Paragraph>
                                    After that, it’s quite intuitive:
                                    <ul>
                                        <li>Select the Bingo mode you would like to use;</li>
                                        <li>Choose all the info about your Bingo (bingo theme, bits price for each card) and click Create Bingo:</li>
                                        <br />
                                        <img src={Tutorial51} alt="tutorial51" style={{ width: '100%', borderRadius: 10 }} />
                                        <br />
                                        <br />
                                        <li>Eventually you can also enable Bingo audios and let your viewers play some audios during the game with bits.</li>
                                    </ul>
                                </Paragraph>
                                <Paragraph>After having created your Bingo, wait for your viewers to join in and when you’re ready and would like to start, click on Start Bingo.</Paragraph>
                                <img src={Tutorial52} alt="tutorial52" style={{ width: '100%', borderRadius: 10 }} />
                                <br />
                                <br />

                                <Paragraph>
                                    After your Bingo has started you can:
                                    <ul>
                                        <li>Draw a random number;</li>
                                        <li>Select a number in the scoreboard to be drawn (this may be useful if you would like to create an IRL Bingo content for your stream, you draw the number IRL and then select the number drawn in the scoreboard!):</li>
                                        <br />
                                        <img src={Tutorial53} alt="tutorial53" style={{ width: '100%', borderRadius: 10 }} />
                                        <br />
                                        <br />
                                    </ul>
                                </Paragraph>

                                <Paragraph>
                                    In the Activity Feed you can follow important actions happened during your Bingo game such as:
                                    <ul>
                                        <li>Cards being unlocked by your viewers with bits, so you can thank the viewer in live;</li>
                                        <li>Audios being played by your viewers with bits;</li>
                                        <li>Declaration of the Chosen One;</li>
                                        <li>Bingo or Line (for EU rules) being called;</li>
                                    </ul>
                                </Paragraph>

                                <Paragraph>When a Bingo or Line is called, you can select the winner and in case of tie, you can choose between declaring all as winners or just selecting one randomly.</Paragraph>
                                <img src={Tutorial54} alt="tutorial54" style={{ width: '100%', borderRadius: 10 }} />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />

                                <Paragraph>If you have any questions or feedback, join our <a href="https://discord.gg/U9DdwM6" target="_blank" rel="noopener noreferrer">Discord</a></Paragraph>
                                <Paragraph>Have fun with Live Bingo!</Paragraph>

                                <div className='embed-container'>
                                    <iframe src="https://www.youtube.com/embed/hf9Dnjc5jkU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>
                            <div id="faq" className="card-page mt-3">
                                <h5 className="fg-primary">FAQ</h5>
                                <hr />
                                <Collapse>
                                    <Panel header={<b>Who is the Chosen One?</b>} key="1">
                                        <p style={{ textAlign: 'left' }}>The Chosen One is a viewer who is playing in your game of Bingo. He is going to be randomly selected from the viewers who have the most cards during that game. Every viewer who is connecting late to your stream, since he hasn’t the possibility to get any card for himself, will see the Chosen One’s card, cheering for him.</p>
                                    </Panel>
                                    <Panel header={<b>Can I earn money using Live Bingo?</b>} key="2">
                                        <p style={{ textAlign: 'left' }}>Of course! Live Bingo is an incentive for Twitch viewers to even use more Bits on the streamers channel. You (streamer) can set a Bits amount that viewers use to unlock cards and play audios. Live Bingo helped streamers earn way more Bits than they usually do and also helped in increasing viewers engagement and retention. When viewers perform and confirm an action that requires Bits within the Extension, streamers will earn 80% of 1 U.S. cent per Bit used as stated by Twitch policies.</p>
                                    </Panel>
                                    <Panel header={<b>How can my viewers join my Live Bingo game and get their cards?</b>} key="3">
                                        <p style={{ textAlign: 'left' }}>Viewers from Desktop should click on the Live Bingo red icon which is going to fluctuate on the stream screen if you set the extension as an overlay OR it’s going to be on the right side of the stream screen if you’ve set it as a component.</p>
                                        <ul style={{ listStyleType: 'none' }}>
                                            <li>
                                                <p style={{ fontSize: 18 }}><b>Overlay example</b></p>
                                                <img src={OverlayGif} alt="overlay-example" style={{ width: '100%', borderRadius: 10 }} />
                                            </li>
                                            <Divider />
                                            <li>
                                                <p style={{ fontSize: 18 }}><b>Component example</b></p>
                                                <img src={ComponentGif} alt="component-example" style={{ width: '100%', borderRadius: 10 }} />
                                            </li>
                                            <Divider />
                                            <li>
                                                <p style={{ fontSize: 18 }}><b>Mobile example</b></p>
                                                <img src={MobileGif} alt="mobile-example" style={{ width: '30%' }} /><br />
                                                (Viewers from Mobile will find the Live Bingo red icon above the Twitch chat.)
                                            </li>
                                        </ul>
                                    </Panel>
                                    <Panel header={<b>How can my viewers play audios?</b>} key="4">
                                        <p style={{ textAlign: 'left' }}>You can select which audio you want to be playable from the Live Bingo dashboard before starting the Bingo game. After doing that, viewers will be able to play audios with bits clicking on the music note button of the extension.</p>
                                    </Panel>
                                    <Panel header={<b>How can my viewers mark their numbers off on their cards and eventually call bingo?</b>} key="5">
                                        <p style={{ textAlign: 'left' }}>Numbers marking is automated! So that your viewers will pay full attention to your live content. The only action the viewers need to perform is eventually calling Bingo to get the victory. A “BINGO!” button is going to appear if one of your viewers owns an eligible card for the win.</p>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />


        </div>
    )
}