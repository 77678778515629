import i18n from '../i18n/i18n'
import DefaultUser from '../img/icons/default-user.png'

export const BINGO_FLAGS = { EU: 'EU', US: 'US' }
export const BINGO_MODES = {
    EU_BINGO: 'EU_BINGO',
    EU_LINE: 'EU_LINE',
    US_CLASSIC: 'US_CLASSIC',
    US_PATTERN: 'US_PATTERN'
}
export const BINGO_THEMES = {
    NONE: 'NONE',
    CLASSIC: 'CLASSIC',
    POKEMON: 'POKEMON',
    POKEMON_GEN2: 'POKEMON_GEN2',
    POKEMON_GEN3: 'POKEMON_GEN3',
    POKEMON_GEN4: 'POKEMON_GEN4',
    POKEMON_GEN5: 'POKEMON_GEN5',
    POKEMON_GEN6: 'POKEMON_GEN6',
    HEARTHSTONE: 'HEARTHSTONE',
    LEAGUE_OF_LEGENDS: 'LEAGUE_OF_LEGENDS',
    MELAGOODO: 'MELAGOODO',
    FOODS: 'FOODS',
    ANIMALS: 'ANIMALS',
    SOCCER_PLAYERS: 'SOCCER_PLAYERS',
    SUPERHEROES: 'SUPERHEROES',
    COUNTRIES: 'COUNTRIES',
    HALLOWEEN: 'HALLOWEEN',
    WINTER: 'WINTER',
    SMORFIA: 'SMORFIA',
    ANIME: 'ANIME',
    SIMPSONS: 'SIMPSONS',
    TV_SERIES: 'TV_SERIES',
    DRAGONBALL: 'DRAGONBALL'
}
export const BINGO_PHASES = { WAITING: 'WAITING', PLAYING: 'PLAYING', COMPLETED: 'COMPLETED', OVER: 'OVER' }
export const SPOLIGHT_TYPE = { NUMBERS: 'NUMBERS', LINE_PEOPLE: 'LINE_PEOPLE', LINE_WINNERS: 'LINE_WINNERS', BINGO_PEOPLE: 'BINGO_PEOPLE', BINGO_WINNERS: 'BINGO_WINNERS' }
export const CUSTOM_THEME = 'CUSTOM_THEME'
const THROWABLE_ITEMS = [
    'TOMATO',
    'POO',
    'EGG',
    'BANANA',
    'STONE',
    'CAKE',
    'SHURIKEN',
    'WATER_BALLOON',
    'VIRUS',
    'DART',
    'DYNAMITE',
    'SUMO_WRESTLER',
    'KISS',
    'BRA',
    'PIGGY_BANK',
    'PUMPKIN',
    'SNOWBALL',
    'PIZZA',
    'FLOWERS',
    'CUPID_ARROW'
]
const SPECIAL_ITEMS = [
    'PIGEON',
    'MARMOT',
    'TOILET_PAPER',
    'DOG',
    'FLY',
    'SHOTGUN',
    'AIR_HORN',
    'TITAN',
    'SAMARA',
    'PENGUIN',
    'FIREWORK',
    'PUNCH',
    'CAT',
    'SQUID',
    'AIRPLANE'
]
export const getPrizeQuantity = (prize) => {
    if (SPECIAL_ITEMS.includes(prize)) return 3
    else return 5
}
export const parseActivityFeedUser = (activityFeedUser) => {
    if (activityFeedUser.display_name == null) {
        activityFeedUser.display_name = i18n.t('livescoreboard.user', { id: activityFeedUser.viewer_id })
    }
    if (activityFeedUser.profile_image_url == null) {
        activityFeedUser.profile_image_url = DefaultUser
    }
    return activityFeedUser
}

export const getNumberLetter = (num) => {
    if (num > 0 && num <= 15) {
        return 'B'
    } else if (num > 15 && num <= 30) {
        return 'I'
    } else if (num > 30 && num <= 45) {
        return 'N'
    } else if (num > 45 && num <= 60) {
        return 'G'
    } else if (num > 60 && num <= 75) {
        return 'O'
    }
}

export const generateCustomThemeVersionUrl = (customTheme) => {
    if (!customTheme) return
    const imageSize = 'HD'
    if (customTheme.logo_v) customTheme.logo = `${process.env.REACT_APP_AWS_S3_ENDPOINT}custom_themes/${customTheme.creator_id}/${customTheme.uuid}/logo_${imageSize}_v${customTheme.logo_v}.png`
    if (customTheme.marker_v) customTheme.marker = `${process.env.REACT_APP_AWS_S3_ENDPOINT}custom_themes/${customTheme.creator_id}/${customTheme.uuid}/marker_${imageSize}_v${customTheme.marker_v}.png`
    if (customTheme.background_v) customTheme.background = `${process.env.REACT_APP_AWS_S3_ENDPOINT}custom_themes/${customTheme.creator_id}/${customTheme.uuid}/background_v${customTheme.background_v}.png`
    if (customTheme.numbers) Object.values(customTheme.numbers).forEach(value => {
        if (value.v) {
            value.img = `${process.env.REACT_APP_AWS_S3_ENDPOINT}custom_themes/${customTheme.creator_id}/${customTheme.uuid}/${value.i}_${imageSize}_v${value.v}.png`
        } else {
            value.img = null
        }
    })
}

export const handleImageError = (target, url) => {
    setTimeout(async () => {
        try {
            target.src = url
        } catch {
            target.src = null
        }
    }, 5000)
}

export const ALL_NAMES = {
    POKEMON: {
        EU: ['bulbasaur', 'ivysaur', 'venusaur', 'charmander', 'charmeleon', 'charizard', 'squirtle', 'wartotle', 'blastoise', 'caterpie', 'weedle', 'pidgey', 'rattata', 'spearow', 'arbok', 'pikachu', 'raichu', 'sandshrew', 'nidoqueen', 'nidoking', 'clefable', 'vulpix', 'jigglypuff', 'zubat', 'vileplume', 'paras', 'venonat', 'diglett', 'meowth', 'psyduck', 'primeape', 'arcanine', 'poliwag', 'abra', 'machop', 'bellsprout', 'tentacool', 'geodude', 'ponyta', 'slowpoke', 'magnemite', 'farfetchd', 'doduo', 'seel', 'muk', 'cloyster', 'gengar', 'onix', 'drowzee', 'krabby', 'voltorb', 'exeggcute', 'marowak', 'hitmonlee', 'hitmonchan', 'lickitung', 'weezing', 'rhydon', 'chansey', 'tangela', 'kangaskhan', 'horsea', 'goldeen', 'starmie', 'mr_mime', 'scyther', 'jynx', 'electabuzz', 'magmar', 'pinsir', 'tauros', 'magikarp', 'gyarados', 'lapras', 'ditto', 'eevee', 'vaporeon', 'jolteon', 'flareon', 'porygon', 'omanyte', 'kabuto', 'aerodactyl', 'snorlax', 'articuno', 'zapdos', 'moltres', 'dragonite', 'mewtwo', 'mew'],
        US: ['bulbasaur', 'ivysaur', 'venusaur', 'charmander', 'charmeleon', 'charizard', 'squirtle', 'wartotle', 'blastoise', 'metapod', 'pidgey', 'rattata', 'arbok', 'pikachu', 'raichu', 'nidoqueen', 'nidoking', 'clefable', 'vulpix', 'jigglypuff', 'zubat', 'vileplume', 'diglett', 'meowth', 'psyduck', 'primeape', 'arcanine', 'poliwag', 'abra', 'machop', 'geodude', 'ponyta', 'slowpoke', 'magnemite', 'farfetchd', 'muk', 'gengar', 'onix', 'drowzee', 'krabby', 'voltorb', 'marowak', 'hitmonlee', 'hitmonchan', 'lickitung', 'weezing', 'rhydon', 'chansey', 'kangaskhan', 'starmie', 'mr_mime', 'scyther', 'electabuzz', 'magmar', 'pinsir', 'tauros', 'magikarp', 'gyarados', 'lapras', 'ditto', 'eevee', 'vaporeon', 'jolteon', 'flareon', 'porygon', 'omanyte', 'kabuto', 'aerodactyl', 'snorlax', 'articuno', 'zapdos', 'moltres', 'dragonite', 'mewtwo', 'mew']
    },
    POKEMON_GEN2: {
        EU: ['chikorita', 'bayleef', 'meganium', 'cyndaquil', 'quilava', 'typhlosion', 'totodile', 'croconaw', 'feraligatr', 'sentret', 'hoothoot', 'noctowl', 'ledyba', 'ledian', 'spinarak', 'ariados', 'crobat', 'chinchou', 'lanturn', 'pichu', 'cleffa', 'igglybuff', 'togepi', 'natu', 'mareep', 'flaaffy', 'ampharos', 'bellossom', 'marill', 'sudowoodo', 'politoed', 'hoppip', 'jumpluff', 'aipom', 'sunkern', 'sunflora', 'yanma', 'wooper', 'espeon', 'umbreon', 'murkrow', 'slowking', 'misdreavus', 'unown', 'wobbuffet', 'girafarig', 'pineco', 'dunsparce', 'gligar', 'steelix', 'snubbull', 'qwilfish', 'scizor', 'shuckle', 'heracross', 'sneasel', 'teddiursa', 'ursaring', 'magcargo', 'swinub', 'piloswine', 'corsola', 'remoraid', 'octillery', 'delibird', 'mantine', 'skarmory', 'houndoom', 'kingdra', 'phanpy', 'donphan', 'porygon2', 'stantler', 'smeargle', 'tyrogue', 'hitmontop', 'smoochum', 'elekid', 'magby', 'miltank', 'blissey', 'raikou', 'entei', 'suicune', 'larvitar', 'pupitar', 'tyranitar', 'lugia', 'hooh', 'celebi'],
        US: ['chikorita', 'meganium', 'cyndaquil', 'typhlosion', 'totodile', 'feraligatr', 'sentret', 'hoothoot', 'ledyba', 'spinarak', 'crobat', 'chinchou', 'pichu', 'cleffa', 'igglybuff', 'togepi', 'natu', 'mareep', 'ampharos', 'bellossom', 'marill', 'sudowoodo', 'politoed', 'hoppip', 'jumpluff', 'aipom', 'sunflora', 'yanma', 'wooper', 'espeon', 'umbreon', 'murkrow', 'slowking', 'misdreavus', 'unown', 'wobbuffet', 'girafarig', 'pineco', 'dunsparce', 'steelix', 'snubbull', 'qwilfish', 'scizor', 'shuckle', 'heracross', 'sneasel', 'teddiursa', 'magcargo', 'piloswine', 'corsola', 'octillery', 'delibird', 'mantine', 'skarmory', 'houndoom', 'kingdra', 'donphan', 'porygon2', 'stantler', 'smeargle', 'tyrogue', 'hitmontop', 'smoochum', 'elekid', 'magby', 'miltank', 'blissey', 'raikou', 'entei', 'suicune', 'larvitar', 'tyranitar', 'lugia', 'hooh', 'celebi']
    },
    POKEMON_GEN3: {
        EU: ['Treecko', 'Grovyle', 'Sceptile', 'Torchic', 'Combusken', 'Blaziken', 'Mudkip', 'Marshtomp', 'Swampert', 'Poochyena', 'Zigzagoon', 'Wurmple', 'Ludicolo', 'Nuzleaf', 'Taillow', 'Pelipper', 'Gardevoir', 'Masquerain', 'Breloom', 'Slaking', 'Ninjask', 'Shedinja', 'Exploud', 'Hariyama', 'Azurill', 'Nosepass', 'Skitty', 'Sableye', 'Mawile', 'Aggron', 'Medicham', 'Manectric', 'Plusle', 'Minun', 'Volbeat', 'Illumise', 'Roselia', 'Gulpin', 'Carvanha', 'Sharpedo', 'Wailord', 'Camerupt', 'Torkoal', 'Spoink', 'Spinda', 'Trapinch', 'Flygon', 'Cacturne', 'Altaria', 'Zangoose', 'Seviper', 'Lunatone', 'Solrock', 'Whiscash', 'Crawdaunt', 'Claydol', 'Cradily', 'Armaldo', 'Feebas', 'Milotic', 'Castform', 'Kecleon', 'Banette', 'Dusclops', 'Tropius', 'Chimecho', 'Absol', 'Wynaut', 'Glalie', 'Spheal', 'Walrein', 'Clamperl', 'Huntail', 'Gorebyss', 'Relicanth', 'Luvdisc', 'Bagon', 'Shelgon', 'Salamence', 'Metagross', 'Regirock', 'Regice', 'Registeel', 'Latias', 'Latios', 'Kyogre', 'Groudon', 'Rayquaza', 'Jirachi', 'Deoxys'],
        US: ['Treecko', 'Sceptile', 'Torchic', 'Blaziken', 'Mudkip', 'Swampert', 'Poochyena', 'Zigzagoon', 'Wurmple', 'Ludicolo', 'Nuzleaf', 'Taillow', 'Pelipper', 'Gardevoir', 'Slaking', 'Ninjask', 'Shedinja', 'Exploud', 'Hariyama', 'Azurill', 'Nosepass', 'Skitty', 'Sableye', 'Mawile', 'Aggron', 'Medicham', 'Manectric', 'Plusle', 'Minun', 'Volbeat', 'Illumise', 'Roselia', 'Gulpin', 'Sharpedo', 'Wailord', 'Camerupt', 'Torkoal', 'Spoink', 'Spinda', 'Flygon', 'Cacturne', 'Altaria', 'Zangoose', 'Seviper', 'Lunatone', 'Solrock', 'Whiscash', 'Claydol', 'Cradily', 'Armaldo', 'Milotic', 'Castform', 'Kecleon', 'Banette', 'Dusclops', 'Chimecho', 'Absol', 'Wynaut', 'Glalie', 'Walrein', 'Clamperl', 'Relicanth', 'Luvdisc', 'Salamence', 'Metagross', 'Regirock', 'Regice', 'Registeel', 'Latias', 'Latios', 'Kyogre', 'Groudon', 'Rayquaza', 'Jirachi', 'Deoxys']
    },
    POKEMON_GEN4: {
        EU: ['Turtwig', 'Grotle', 'Torterra', 'Chimchar', 'Monferno', 'Infernape', 'Piplup', 'Prinplup', 'Empoleon', 'Starly', 'Staraptor', 'Bidoof', 'Bibarel', 'Kricketune', 'Shinx', 'Luxray', 'Budew', 'Roserade', 'Cranidos', 'Rampardos', 'Bastiodon', 'Burmy', 'Mothim', 'Combee', 'Vespiquen', 'Pachirisu', 'Floatzel', 'Cherubi', 'Gastrodon', 'Ambipom', 'Drifloon', 'Lopunny', 'Mismagius', 'Honchkrow', 'Purugly', 'Chingling', 'Stunky', 'Bronzor', 'Bronzong', 'Bonsly', 'Mime-Jr', 'Happiny', 'Chatot', 'Spiritomb', 'Gible', 'Garchomp', 'Munchlax', 'Riolu', 'Lucario', 'Hippopotas', 'Hippowdon', 'Drapion', 'Croagunk', 'Carnivine', 'Lumineon', 'Mantyke', 'Abomasnow', 'Weavile', 'Magnezone', 'Lickilicky', 'Rhyperior', 'Tangrowth', 'Electivire', 'Magmortar', 'Togekiss', 'Yanmega', 'Leafeon', 'Glaceon', 'Gliscor', 'Mamoswine', 'Porygon-Z', 'Gallade', 'Probopass', 'Dusknoir', 'Froslass', 'Rotom', 'Uxie', 'Mesprit', 'Azelf', 'Dialga', 'Palkia', 'Heatran', 'Regigigas', 'Giratina', 'Cresselia', 'Phione', 'Manaphy', 'Darkrai', 'Shaymin', 'Arceus'],
        US: ['Turtwig', 'Torterra', 'Chimchar', 'Infernape', 'Piplup', 'Empoleon', 'Staraptor', 'Bibarel', 'Kricketune', 'Luxray', 'Roserade', 'Cranidos', 'Rampardos', 'Bastiodon', 'Combee', 'Pachirisu', 'Floatzel', 'Cherubi', 'Gastrodon', 'Ambipom', 'Drifloon', 'Lopunny', 'Mismagius', 'Honchkrow', 'Chingling', 'Stunky', 'Bronzong', 'Bonsly', 'Mime-Jr', 'Happiny', 'Chatot', 'Spiritomb', 'Garchomp', 'Munchlax', 'Lucario', 'Hippopotas', 'Drapion', 'Croagunk', 'Carnivine', 'Lumineon', 'Mantyke', 'Abomasnow', 'Weavile', 'Magnezone', 'Lickilicky', 'Rhyperior', 'Tangrowth', 'Electivire', 'Magmortar', 'Togekiss', 'Yanmega', 'Leafeon', 'Glaceon', 'Gliscor', 'Mamoswine', 'Porygon-Z', 'Gallade', 'Probopass', 'Dusknoir', 'Froslass', 'Rotom', 'Uxie', 'Mesprit', 'Azelf', 'Dialga', 'Palkia', 'Heatran', 'Regigigas', 'Giratina', 'Cresselia', 'Phione', 'Manaphy', 'Darkrai', 'Shaymin', 'Arceus']
    },
    POKEMON_GEN5: {
        EU: ['494', '495', '496', '497', '498', '499', '500', '501', '502', '503', '504', '506', '509', '511', '513', '515', '518', '521', '523', '526', '528', '530', '531', '534', '537', '538', '539', '542', '545', '547', '549', '550', '553', '555', '556', '558', '559', '561', '563', '565', '567', '569', '570', '571', '573', '576', '579', '581', '584', '585', '586', '587', '589', '591', '593', '594', '596', '598', '599', '604', '605', '609', '612', '613', '615', '616', '618', '619', '621', '623', '625', '626', '628', '630', '631', '632', '635', '637', '638', '639', '640', '641', '642', '643', '644', '645', '646', '647', '648', '649'],
        US: ['494', '495', '497', '498', '500', '501', '503', '504', '506', '511', '513', '515', '518', '523', '526', '528', '530', '531', '534', '537', '538', '539', '545', '549', '550', '553', '555', '556', '558', '559', '561', '563', '565', '567', '569', '571', '573', '576', '579', '581', '584', '585', '586', '587', '589', '591', '593', '596', '598', '599', '604', '609', '612', '613', '618', '619', '625', '626', '628', '630', '631', '635', '637', '638', '639', '640', '641', '642', '643', '644', '645', '646', '647', '648', '649']
    },
    POKEMON_GEN6: {
        EU: ['650', '651', '652', '653', '654', '655', '656', '657', '658', '659', '660', '661', '662', '663', '664', '665', '666', '667', '668', '669', '670', '671', '672', '673', '674', '675', '676', '677', '678', '679', '680', '681', '682', '683', '684', '685', '686', '687', '688', '689', '690', '691', '692', '693', '694', '695', '696', '697', '698', '699', '700', '701', '702', '703', '704', '705', '706', '707', '708', '709', '710', '711', '712', '713', '714', '715', '716', '717', '718', '719', '720', '721', '000_09', '000_10', '000_11', '000_12', '000_15', '000_17', '000_18', '000_20', '000_22', '000_23', '000_24', '000_25', '000_38', '000_44', '000_46', '000_49', '000_51', '000_52'],
        US: ['650', '652', '653', '655', '656', '658', '659', '660', '661', '663', '664', '666', '667', '668', '669', '671', '673', '674', '675', '676', '677', '678', '679', '681', '683', '685', '686', '687', '688', '689', '691', '692', '693', '694', '695', '697', '698', '699', '700', '701', '702', '703', '704', '706', '707', '708', '709', '711', '713', '714', '715', '716', '717', '718', '719', '720', '721', '000_09', '000_10', '000_11', '000_12', '000_15', '000_17', '000_18', '000_20', '000_22', '000_23', '000_24', '000_25', '000_38', '000_44', '000_46', '000_49', '000_51', '000_52']
    },
    HEARTHSTONE: {
        EU: ['frog', 'wisp', 'sheep', 'snowflipper_penguin', 'mr_bigglesworth', 'argent_squire', 'angry_chicken', 'patches_the_pirate', 'flame_imp', 'leper_gnome', 'bluegill_warrior', 'sorcerers_apprentice', 'dirty_rat', 'lorewalker_cho', 'knife_juggler', 'bloodmage_thalnos', 'doomsayer', 'nat_pagle', 'millhouse_manastorm', 'khadgar', 'ironbeak_owl', 'si7_agent', 'mana_tide_totem', 'tinkmaster_overspark', 'frothing_berserker', 'brann_bronzebeard', 'kirin_tor_mage', 'mind_control_tech', 'king_mukla', 'edwin_vancleef', 'bad_luck_albatross', 'altruis_the_outcast', 'defender_of_argus', 'twilight_drake', 'sky_genral_kragg', 'gnomish_inventor', 'senjin_shieldmasta', 'water_elemental', 'houndmaster', 'chillwind_yeti', 'big_game_hunter', 'leeroy_jenkins', 'barnes', 'jandice_barov', 'captain_greenskin', 'harrison_jones', 'headmaster_kelthuzad', 'abomination', 'elise_the_enlightened', 'stranglethorn_tiger', 'hogger', 'kronx_dragonhoof', 'gadgetzan_auctioneer', 'heistbaron_togwaggle', 'boulderfist_ogre', 'reno_the_relicologist', 'savannah_highmane', 'sylvanas_windrunner', 'the_black_knight', 'cabal_shadow_priest', 'ancient_of_lore', 'ancient_of_war', 'archmage_antonidas', 'exotic_mountseller', 'baron_geddon', 'chef_nomi', 'dr_boom', 'archvillain_rafaam', 'siamat', 'kaelthas_sunstrider', 'molten_giant', 'mountain_giant', 'sea_giant', 'sindragosa', 'grommash_hellscream', 'medivh_the_guardian', 'tess_greymane', 'the_lich_king', 'tirion_fordring', 'ragnaros', 'alexstrasza', 'malygos', 'ysera', 'nozdormu', 'king_krush', 'shudderwock', 'lord_jaraxxus', 'deathwing', 'cthun', 'yoggsaron'],
        US: ['frog', 'sheep', 'snowflipper_penguin', 'mr_bigglesworth', 'argent_squire', 'angry_chicken', 'leper_gnome', 'bluegill_warrior', 'sorcerers_apprentice', 'dirty_rat', 'lorewalker_cho', 'bloodmage_thalnos', 'doomsayer', 'nat_pagle', 'millhouse_manastorm', 'ironbeak_owl', 'si7_agent', 'mana_tide_totem', 'tinkmaster_overspark', 'brann_bronzebeard', 'mind_control_tech', 'king_mukla', 'edwin_vancleef', 'bad_luck_albatross', 'altruis_the_outcast', 'defender_of_argus', 'twilight_drake', 'senjin_shieldmasta', 'houndmaster', 'chillwind_yeti', 'big_game_hunter', 'leeroy_jenkins', 'barnes', 'jandice_barov', 'captain_greenskin', 'harrison_jones', 'abomination', 'elise_the_enlightened', 'kronx_dragonhoof', 'gadgetzan_auctioneer', 'heistbaron_togwaggle', 'boulderfist_ogre', 'reno_the_relicologist', 'sylvanas_windrunner', 'cabal_shadow_priest', 'ancient_of_lore', 'ancient_of_war', 'archmage_antonidas', 'chef_nomi', 'dr_boom', 'archvillain_rafaam', 'siamat', 'kaelthas_sunstrider', 'molten_giant', 'mountain_giant', 'sea_giant', 'tess_greymane', 'the_lich_king', 'tirion_fordring', 'ragnaros', 'alexstrasza', 'malygos', 'ysera', 'nozdormu', 'onyxia', 'king_krush', 'shudderwock', 'cenarius', 'lord_jaraxxus', 'king_phaoris', 'nozari', 'kalecgos', 'deathwing', 'cthun', 'yoggsaron']
    },
    LEAGUE_OF_LEGENDS: {
        EU: ['aatrox', 'ahri', 'akali', 'alistar', 'amumu', 'annie', 'ashe', 'blitzcrank', 'brand', 'caitlyn', 'camille', 'chogath', 'darius', 'ekko', 'elise', 'ezreal', 'fiddlesticks', 'fiora', 'galio', 'gangplank', 'garen', 'gragas', 'graves', 'heimerdinger', 'irelia', 'jarvan_iv', 'jax', 'jayce', 'jhin', 'jinx', 'kaisa', 'karthus', 'kassadin', 'katarina', 'kayle', 'kennen', 'kogmaw', 'leblanc', 'lee_sin', 'leona', 'lucian', 'lulu', 'lux', 'malphite', 'maokai', 'master_yi', 'miss_fortune', 'mordekaiser', 'morgana', 'nasus', 'nautilus', 'nidalee', 'nocturne', 'nunu_&_willump', 'olaf', 'pantheon', 'poppy', 'pyke', 'qiyana', 'rammus', 'renekton', 'rengar', 'riven', 'ryze', 'senna', 'sett', 'shaco', 'shen', 'singed', 'sivir', 'sona', 'soraka', 'sylas', 'syndra', 'teemo', 'thresh', 'tristana', 'tryndamere', 'twisted_fate', 'urgot', 'vayne', 'veigar', 'vi', 'vladimir', 'volibear', 'warwick', 'yasuo', 'zac', 'zed', 'zilean'],
        US: ['aatrox', 'ahri', 'akali', 'alistar', 'amumu', 'annie', 'ashe', 'blitzcrank', 'caitlyn', 'camille', 'chogath', 'darius', 'ekko', 'elise', 'ezreal', 'fiddlesticks', 'fiora', 'galio', 'gangplank', 'garen', 'gragas', 'graves', 'heimerdinger', 'irelia', 'jarvan_iv', 'jax', 'jhin', 'jinx', 'kaisa', 'katarina', 'kayle', 'leblanc', 'lee_sin', 'leona', 'lucian', 'lux', 'malphite', 'maokai', 'master_yi', 'miss_fortune', 'mordekaiser', 'morgana', 'nasus', 'nautilus', 'nocturne', 'nunu_&_willump', 'olaf', 'pyke', 'rammus', 'renekton', 'rengar', 'riven', 'ryze', 'senna', 'sett', 'shaco', 'shen', 'singed', 'sivir', 'sylas', 'syndra', 'teemo', 'thresh', 'tristana', 'tryndamere', 'twisted_fate', 'urgot', 'vayne', 'veigar', 'vladimir', 'volibear', 'warwick', 'yasuo', 'zac', 'zed']
    },
    FOODS: {
        EU: ['apple', 'asparagus', 'avocado', 'bacon', 'banana', 'beer', 'blueberries', 'bread', 'broccoli', 'cabbage', 'carrot', 'cereals', 'cheese', 'cherry', 'chicken', 'chili_pepper', 'chocolate', 'cocktail', 'coconut', 'coffee', 'cookies', 'corn', 'cotton_candy', 'crab', 'croissant', 'cucumber', 'cupcake', 'dim_sum', 'doughnut', 'dragon_fruit', 'egg', 'eggplant', 'frappe', 'french_fries', 'garlic', 'gingerbread', 'grapes', 'hamburger', 'hazelnut', 'honey', 'hot_dog', 'ice_cream', 'kebab', 'kiwi', 'lemon', 'lettuce', 'macaron', 'melon', 'milk', 'mushrooms', 'nachos', 'noodles', 'olive_oil', 'olives', 'onion', 'orange', 'pancake', 'peach', 'peanuts', 'pear', 'peas', 'pepper', 'pineapple', 'pizza', 'plum', 'pomegranate', 'potato', 'prawn', 'pretzel', 'pumpkin', 'rack_of_lamb', 'radish', 'raspberry', 'rice', 'salmon', 'salt', 'sausage', 'spaghetti', 'steak', 'strawberry', 'sugar', 'sushi', 'sweet_pepper', 'taco', 'tea', 'tomato', 'water', 'watermelon', 'wine', 'wrap'],
        US: ['apple', 'avocado', 'bacon', 'banana', 'beer', 'bread', 'broccoli', 'carrot', 'cereals', 'cheese', 'cherry', 'chicken', 'chili_pepper', 'chocolate', 'coconut', 'coffee', 'cookies', 'corn', 'crab', 'croissant', 'cupcake', 'dim_sum', 'doughnut', 'egg', 'eggplant', 'frappe', 'french_fries', 'grapes', 'hamburger', 'honey', 'hot_dog', 'ice_cream', 'kebab', 'kiwi', 'lemon', 'macaron', 'melon', 'milk', 'mushrooms', 'nachos', 'noodles', 'olive_oil', 'onion', 'orange', 'pancake', 'peach', 'peanuts', 'pear', 'peas', 'pineapple', 'pizza', 'plum', 'pomegranate', 'potato', 'prawn', 'pretzel', 'rack_of_lamb', 'radish', 'raspberry', 'rice', 'salmon', 'salt', 'sausage', 'spaghetti', 'steak', 'strawberry', 'sugar', 'sushi', 'sweet_pepper', 'taco', 'tea', 'tomato', 'water', 'watermelon', 'wine']
    },
    ANIMALS: {
        EU: ['ant', 'anteater', 'bear', 'bee', 'beetle', 'bison', 'camel', 'cat', 'chameleon', 'cheetah', 'chicken', 'clown_fish', 'cougar', 'cow', 'crab', 'crocodile', 'deer', 'dog', 'dolphin', 'duck', 'eagle', 'elephant', 'flamingo', 'fox', 'frog', 'giraffe', 'goat', 'gorilla', 'hamster', 'hedgehog', 'hippopotamus', 'horse', 'hummingbird', 'jellyfish', 'kangaroo', 'kiwi', 'koala', 'ladybug', 'lemur', 'leopard', 'lion', 'llama', 'lobster', 'manta_ray', 'monkey', 'octopus', 'orangutan', 'orca', 'ostrich', 'otter', 'owl', 'panda', 'panther', 'peacock', 'penguin', 'pig', 'pigeon', 'platypus', 'polar_bear', 'prawn', 'praying_mantis', 'puffer_fish', 'rabbit', 'raccoon', 'rat', 'reindeer', 'rhinoceros', 'salmon', 'scorpion', 'seahorse', 'seal', 'shark', 'sheep', 'skunk', 'sloth', 'snail', 'snake', 'spider', 'squirrel', 'starfish', 'swan', 'tiger', 'toucan', 'turkey', 'turtle', 'walrus', 'whale', 'wolf', 'yak', 'zebra'],
        US: ['ant', 'bear', 'bee', 'camel', 'cat', 'chameleon', 'cheetah', 'chicken', 'clown_fish', 'cow', 'crab', 'crocodile', 'deer', 'dog', 'dolphin', 'duck', 'eagle', 'elephant', 'flamingo', 'fox', 'frog', 'giraffe', 'goat', 'gorilla', 'hamster', 'hedgehog', 'hippopotamus', 'horse', 'hummingbird', 'kangaroo', 'kiwi', 'koala', 'ladybug', 'lion', 'llama', 'lobster', 'manta_ray', 'monkey', 'octopus', 'orangutan', 'orca', 'ostrich', 'otter', 'owl', 'panda', 'panther', 'peacock', 'penguin', 'pig', 'pigeon', 'platypus', 'prawn', 'rabbit', 'raccoon', 'reindeer', 'rhinoceros', 'salmon', 'scorpion', 'seahorse', 'seal', 'shark', 'sheep', 'skunk', 'sloth', 'snake', 'spider', 'squirrel', 'starfish', 'swan', 'tiger', 'toucan', 'turtle', 'whale', 'wolf', 'zebra']
    },
    MELAGOODO: {
        EU: ['MELAGOODO', 'JOHNNY_CREEK', 'DANIBATTLE_E_FITZA', 'SOFIA', 'tumbluClown', 'tumbluEmoteDelFazzone', 'tumbluGgggggg', 'tumbluHamburgerona', 'tumbluSium', 'BLUR', 'BEKYM', 'zanoATHENA', 'zanoLove', 'zanoNext', 'zanoShop', 'zanoWe', 'ZANO', 'chakraBit', 'chakraEhm', 'chakraFlauto', 'chakraFotto', 'chakraFuoco', 'CHAKRA', 'freneAkimbo', 'freneEclisse', 'freneItas', 'freneMogano', 'freneScam', 'FRENEZY', 'deluBobbi', 'deluHarmane', 'deluPesce', 'deluPog', 'deluShop', 'DELUX', 'molluApproved', 'molluC', 'molluDissocio', 'molluGasm', 'molluPoo', 'MOLLU', 'ilmassCursed', 'ilmassDancer', 'ilmassHU', 'ilmassP', 'ilmassSchei', 'MASSEO', 'giankoAttacco', 'giankoPog', 'giankoRivolta', 'giankoSleeper', 'giankoTifo', 'GIANKO', 'jtazAww', 'jtazMiao', 'jtazPeluche', 'jtazWow', 'jtazZugu', 'JTAZ', 'marzaAhri', 'marzaMadre', 'marzaPiscio', 'marzaSigaretta', 'marzaTrimone', 'MARZA', 'faz025', 'fazM', 'fazMonika', 'fazPollice', 'fazUe', 'FAZZ', 'MICHELLE', 'dreadDread', 'dreadEuro', 'dreadLasciaPerde', 'dreadNala', 'dreadRigolo', 'DREAD', 'gabboGuaddi', 'gabboNonno', 'gabboOke', 'gabboPaky', 'gabboSonno', 'GABBO', 'rohnCry', 'rohnGrrr', 'rohnKiss', 'rohnOuch', 'rohnPOGGERS', 'ROHN']
    },
    SOCCER_PLAYERS: {
        EU: ['aguero', 'alex_morgan', 'alisson', 'baggio', 'bale', 'batistuta', 'beckenbauer', 'beckham', 'benzema', 'buffon', 'cafu', 'cannavaro', 'cantona', 'carli_lloyd', 'casillas', 'cavani', 'cech', 'chiellini', 'cristiano_ronaldo', 'cruijff', 'de_bruyne', 'del_piero', 'di_maria', 'drogba', 'dybala', 'dzeko', 'etoo', 'figo', 'gerrard', 'giggs', 'griezmann', 'gullit', 'haland', 'henry', 'ibrahimovic', 'immobile', 'iniesta', 'jasin', 'kaka', 'kane', 'kroos', 'lahm', 'lampard', 'lewandowski', 'lukaku', 'maldini', 'maradona', 'mbappe', 'messi', 'modric', 'muller', 'nedved', 'neuer', 'neymar', 'oblak', 'owen', 'ozil', 'pele', 'pique', 'pirlo', 'platini', 'pogba', 'puyol', 'ramos', 'ribery', 'robben', 'roberto_carlos', 'ronaldinho', 'ronaldo', 'rooney', 'rummenigge', 'salah', 'scholes', 'seedorf', 'shevchenko', 'son', 'suarez', 'tevez', 'thiago_silva', 'torres', 'totti', 'trezeguet', 'van_basten', 'van_dijk', 'van_nistelrooy', 'vardy', 'vidal', 'xavi', 'zanetti', 'zidane'],
        US: ['aguero', 'alex_morgan', 'alisson', 'bale', 'batistuta', 'beckenbauer', 'beckham', 'benzema', 'buffon', 'cafu', 'cannavaro', 'carli_lloyd', 'casillas', 'cavani', 'cech', 'chiellini', 'cristiano_ronaldo', 'cruijff', 'de_bruyne', 'del_piero', 'di_maria', 'drogba', 'dybala', 'dzeko', 'etoo', 'figo', 'gerrard', 'griezmann', 'gullit', 'haland', 'henry', 'ibrahimovic', 'immobile', 'iniesta', 'jasin', 'kaka', 'kane', 'kroos', 'lewandowski', 'lukaku', 'maldini', 'maradona', 'mbappe', 'messi', 'modric', 'muller', 'nedved', 'neuer', 'neymar', 'oblak', 'owen', 'pele', 'pique', 'pirlo', 'platini', 'pogba', 'ramos', 'ribery', 'robben', 'roberto_carlos', 'ronaldinho', 'ronaldo', 'salah', 'seedorf', 'son', 'suarez', 'tevez', 'thiago_silva', 'totti', 'trezeguet', 'van_dijk', 'vardy', 'vidal', 'zanetti', 'zidane']
    },
    SUPERHEROES: {
        EU: ['ant-man', 'aquaman', 'bane', 'batman', 'beast_boy', 'black_panther', 'black_widow', 'captain_america', 'captain_cold', 'captain_marvel', 'carnage', 'catwoman', 'colossus', 'cyborg', 'cyclops', 'daredevil', 'deadpool', 'dormammu', 'dr_doom', 'dr_octopus', 'dr_strange', 'drax_the_destroyer', 'elastigirl', 'falcon', 'flash_incredibles', 'flash', 'frozone', 'galactus', 'gambit', 'gamora', 'goblin', 'green_lantern', 'groot', 'harley_quinn', 'hawkeye', 'hellblazer', 'hellboy', 'hulk', 'human_torch', 'invisible_woman', 'iron_man', 'jack-jack', 'jean_grey', 'joker', 'judge_dredd', 'klaw', 'lex_luthor', 'loki', 'magneto', 'martian_manhunter', 'mr_fantastic', 'mr_incredible', 'nebula', 'poison_ivy', 'professor_x', 'quicksilver', 'ras_al_ghul', 'raven', 'red_skull', 'reverse_flash', 'robin', 'rocket', 'rorschach', 'sandman', 'scarecrow', 'scarlet_witch', 'sentry', 'shazam', 'silver_surfer', 'sinestro', 'spider-man', 'star_lord', 'storm', 'superman', 'syndrome', 'thanos', 'the_punisher', 'the_thing', 'the_vulture', 'thor', 'two-face', 'ultron', 'venom', 'violet', 'vision', 'war_machine', 'wasp', 'wolverine', 'wonder_woman', 'x-23'],
        US: ['ant-man', 'aquaman', 'bane', 'batman', 'black_panther', 'black_widow', 'captain_america', 'captain_cold', 'captain_marvel', 'carnage', 'catwoman', 'colossus', 'cyclops', 'daredevil', 'deadpool', 'dormammu', 'dr_doom', 'dr_octopus', 'dr_strange', 'drax_the_destroyer', 'falcon', 'flash', 'galactus', 'gambit', 'gamora', 'goblin', 'green_lantern', 'groot', 'harley_quinn', 'hawkeye', 'hellblazer', 'hellboy', 'hulk', 'human_torch', 'invisible_woman', 'iron_man', 'jean_grey', 'joker', 'lex_luthor', 'loki', 'magneto', 'mr_fantastic', 'nebula', 'poison_ivy', 'professor_x', 'quicksilver', 'ras_al_ghul', 'red_skull', 'reverse_flash', 'robin', 'rocket', 'rorschach', 'sandman', 'scarecrow', 'scarlet_witch', 'sentry', 'shazam', 'silver_surfer', 'sinestro', 'spider-man', 'star_lord', 'storm', 'superman', 'thanos', 'the_punisher', 'the_thing', 'thor', 'two-face', 'ultron', 'venom', 'vision', 'war_machine', 'wasp', 'wolverine', 'wonder_woman']
    },
    COUNTRIES: {
        EU: ['albania', 'algeria', 'andorra', 'argentina', 'australia', 'austria', 'azerbaijan', 'bangladesh', 'belgium', 'bosnia-and-herzegovina', 'brazil', 'bulgaria', 'canada', 'chile', 'china', 'colombia', 'croatia', 'cuba', 'cyprus', 'czech-republic', 'denmark', 'ecuador', 'egypt', 'england', 'estonia', 'finland', 'france', 'germany', 'ghana', 'greece', 'hungary', 'iceland', 'india', 'indonesia', 'iran', 'iraq', 'ireland', 'israel', 'italy', 'jamaica', 'japan', 'kazakhstan', 'kosovo', 'liechtenstein', 'lithuania', 'luxembourg', 'malta', 'mexico', 'moldova', 'monaco', 'mongolia', 'montenegro', 'morocco', 'nepal', 'netherlands', 'new-zealand', 'north-korea', 'norway', 'pakistan', 'peru', 'philippines', 'portugal', 'qatar', 'republic-of-macedonia', 'republic-of-poland', 'romania', 'russia', 'san-marino', 'saudi-arabia', 'scotland', 'senegal', 'serbia', 'slovakia', 'slovenia', 'south-africa', 'south-korea', 'spain', 'sudan', 'sweden', 'switzerland', 'thailand', 'tunisia', 'turkey', 'ukraine', 'united-states-of-america', 'uruguay', 'vatican-city', 'venezuela', 'vietnam', 'wales'],
        US: ['antigua-and-barbuda', 'argentina', 'australia', 'austria', 'bahamas', 'barbados', 'belgium', 'belize', 'bolivia', 'brazil', 'canada', 'chile', 'china', 'colombia', 'costa-rica', 'croatia', 'cuba', 'denmark', 'dominica', 'dominican-republic', 'ecuador', 'egypt', 'el-salvador', 'england', 'france', 'germany', 'greece', 'grenada', 'guatemala', 'guyana', 'haiti', 'honduras', 'india', 'iran', 'iraq', 'israel', 'italy', 'jamaica', 'japan', 'kazakhstan', 'mexico', 'mongolia', 'morocco', 'nepal', 'netherlands', 'new-zealand', 'nicaragua', 'north-korea', 'panama', 'paraguay', 'peru', 'portugal', 'qatar', 'republic-of-the-congo', 'russia', 'saint-kitts-and-nevis', 'saudi-arabia', 'south-africa', 'south-korea', 'spain', 'st-lucia', 'st-vincent-and-the-grenadines', 'sudan', 'suriname', 'sweden', 'switzerland', 'thailand', 'trinidad-and-tobago', 'turkey', 'ukraine', 'united-states-of-america', 'uruguay', 'vatican-city', 'venezuela', 'vietnam']
    },
    HALLOWEEN: {
        EU: ['jack', 'sally', 'dr_finkelstein', 'zero', 'mayor', 'barrel', 'lock', 'shock', 'oogie_boogie', 'hand', 'gomez_addams', 'morticia', 'wednesday', 'pugsley', 'uncle_fester', 'cousin_itt', 'grandmama', 'lurch', 'emily', 'victor', 'casper', 'stinkie', 'stretch', 'fatso', 'haunted_house', 'full_moon', 'coffin', 'tomb', 'ouija_board', 'voodoo_doll', 'skull', 'head_in_jar', 'monster_eye', 'vampire_teeth', 'paper_bag_mask', 'candies', 'halloween_pumpkin', 'potion_of_poison', 'magic_ball', 'witch_cauldron', 'witch_hat', 'magic_broom', 'witch', 'ghost', 'mummy', 'zombie', 'werewolf', 'dracula', 'reaper', 'devil', 'bat', 'spider', 'black_cat', 'crow', 'owl', 'alien', 'predator', 'slappy', 'momo', 'slender_man', 'frankenstein_monster', 'headless_horseman', 'scarecrow', 'frank', 'jack_torrance', 'annabelle', 'babadook', 'billy', 'brahms', 'chucky', 'the_nun', 'regan_macneil', 'samara', 'freddy', 'hannibal', 'pinhead', 'pennywise_1990', 'pennywise_2017', 'jason_voorhees', 'leatherface', 'leprechaun', 'ghostface', 'michael_myers', 'norman_bates', 'the_beast', 'creepy_baby_mask', 'the_purge_mask_happy', 'the_purge_mask_god', 'the_purge_mask_kissme', 'the_purge_mask_lights'],
        US: ['jack', 'sally', 'dr_finkelstein', 'zero', 'mayor', 'barrel', 'lock', 'shock', 'oogie_boogie', 'emily', 'victor', 'casper', 'stinkie', 'stretch', 'fatso', 'gomez_addams', 'morticia', 'wednesday', 'pugsley', 'uncle_fester', 'cousin_itt', 'grandmama', 'lurch', 'hand', 'haunted_house', 'full_moon', 'coffin', 'tomb', 'ouija_board', 'voodoo_doll', 'skull', 'head_in_jar', 'monster_eye', 'vampire_teeth', 'paper_bag_mask', 'candies', 'halloween_pumpkin', 'potion_of_poison', 'magic_ball', 'witch_cauldron', 'witch_hat', 'magic_broom', 'witch', 'ghost', 'mummy', 'zombie', 'werewolf', 'dracula', 'reaper', 'devil', 'bat', 'spider', 'black_cat', 'crow', 'slappy', 'momo', 'slender_man', 'frankenstein_monster', 'headless_horseman', 'scarecrow', 'jack_torrance', 'annabelle', 'babadook', 'billy', 'chucky', 'the_nun', 'regan_macneil', 'samara', 'freddy', 'hannibal', 'pennywise_2017', 'jason_voorhees', 'leatherface', 'michael_myers', 'ghostface']
    },
    WINTER: {
        EU: ['penguin', 'wolf', 'owl', 'deer', 'bear', 'fishing', 'eskimo', 'igloo', 'icicle', 'earmuffs', 'winter-hat', 'scarf', 'glasses', 'jacket', 'sweater', 'shirt', 'mitten', 'boot', 'tire', 'car', 'bird', 'mountain', 'yeti', 'cable-car-cabin', 'ramp', 'ski', 'ski-sport', 'ski-jumping', 'snowboard', 'snowboard-sport', 'ice-hockey', 'curling', 'ice-skate', 'ice-skating', 'sled', 'bobsled', 'snowmobile', 'avalanche', 'snowplow', 'shovel', 'raincoat', 'snow', 'snow-ball', 'snow-angel', 'snowman', 'small-sled', 'snowshoes', 'house', 'window', 'radiator', 'blanket', 'slippers', 'cold', 'thermometer', 'heater', 'soup', 'chocolate', 'tea-cup', 'thermo', 'calendar', 'mistletoe', 'acorn', 'pine-cones', 'jingle-bells', 'boy', 'candy-cane', 'shooting-star', 'north-pole', 'santa-claus-sled', 'santa-claus', 'chimney', 'christmas-hat', 'santa-claus-dress', 'christmas-sock', 'matchbox', 'fireplace', 'christmas-card', 'breakfast', 'snow-globe', 'rocking-horse', 'soldier', 'christmas-present', 'christmas-tree', 'star', 'christmas-ball', 'angel', 'gingerbread', 'cheers', 'confetti', 'firework'],
        US: ['penguin', 'wolf', 'deer', 'bear', 'fishing', 'eskimo', 'igloo', 'icicle', 'earmuffs', 'winter-hat', 'scarf', 'glasses', 'sweater', 'mitten', 'boot', 'tire', 'bird', 'yeti', 'cable-car-cabin', 'ramp', 'ski-sport', 'ski-jumping', 'snowboard-sport', 'ice-hockey', 'curling', 'ice-skating', 'bobsled', 'snowmobile', 'avalanche', 'snowplow', 'shovel', 'raincoat', 'snow', 'snow-ball', 'snow-angel', 'snowman', 'small-sled', 'snowshoes', 'house', 'window', 'slippers', 'cold', 'thermometer', 'chocolate', 'heater', 'calendar', 'mistletoe', 'acorn', 'pine-cones', 'jingle-bells', 'boy', 'candy-cane', 'shooting-star', 'north-pole', 'santa-claus-sled', 'santa-claus', 'chimney', 'christmas-hat', 'santa-claus-dress', 'christmas-sock', 'fireplace', 'christmas-card', 'breakfast', 'snow-globe', 'rocking-horse', 'soldier', 'christmas-present', 'christmas-tree', 'star', 'christmas-ball', 'angel', 'gingerbread', 'cheers', 'confetti', 'firework']
    },
    SMORFIA: {
        EU: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90'],
        US: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75']
    },
    ANIME: {
        EU: ['Goku', 'Vegeta', 'Gohan_Kid', 'Krillin', 'Roshi', 'Bulma', 'Junior', 'Freezer', 'Cell', 'Majin_Bu', 'Monkey_D_Luffy', 'Zoro_Roronoa', 'Nami', 'Usop', 'Sanji', 'Chopper', 'Arale', 'Ash_Ketchum', 'Doraemon', 'Nobita', 'Naruto', 'Sasuke', 'Sakura', 'Kakashi', 'Orochimaru', 'Holly', 'Benji', 'Mark_Lenders', 'Mila', 'Shiro_Takiki', 'Eren', 'Mikasa', 'Armin', 'Levi', 'Colossal_Titan', 'Armored_Titan', 'Saitama', 'Genos', 'Tanjiro', 'Nezuko', 'Light_Yagami', 'L', 'Misa_Amane', 'Ryuk', 'Okabe_Rintarou', 'Kurisu_Makise', 'Shiina_Mayuri', 'Chihiro_Ogiro', 'No-Face', 'Yubaba', 'Edward_Elric', 'Alphonse_Elric', 'Roy_Mustang', 'Detective_Conan', 'Goro', 'Yami_Yugi', 'Seto_Kaiba', 'Sailor_Moon', 'Tuxedo_Mask', 'Lelouch_VI', 'Gon_Freecss', 'Leorio_Paladiknight', 'Kurapika', 'Midoriya', 'Natsu_Dragneel', 'Eikichi_Onizuka', 'Hanamichi_Sakuragi', 'Ichigo_Kurosaki', 'Kenshiro', 'Gundam', 'Jotaro_Kujo', 'Dio_Brando', 'Giorno_Giovanna', 'Inuyasha', 'Lum', 'Pegaso', 'Rocky_Joe', 'Sakata_Gintoki', 'Senku_Ishigami', 'Yoh_Asakura', 'Lupin_lll', 'Jigen', 'Goemon', 'Fukijo_Mine', 'Koichi_Zenigata', 'Kaneki_Ken', 'Shinji', 'Kenzou_Tenma', 'Koro_Sensei', 'Shin_Chan'],
        US: ['Goku', 'Vegeta', 'Freezer', 'Cell', 'Majin_Bu', 'Arale', 'Pegaso', 'Doraemon', 'Nobita', 'Lum', 'Ash_Ketchum', 'Rocky_Joe', 'Sakata_Gintoki', 'Senku_Ishigami', 'Yoh_Asakura', 'Monkey_D_Luffy', 'Zoro_Roronoa', 'Nami', 'Usop', 'Sanji', 'Gon_Freecss', 'Leorio_Paladiknight', 'Kurapika', 'Midoriya', 'Natsu_Dragneel', 'Eikichi_Onizuka', 'Hanamichi_Sakuragi', 'Ichigo_Kurosaki', 'Kenshiro', 'Gundam', 'Naruto', 'Sasuke', 'Sakura', 'Kakashi', 'Orochimaru', 'Holly', 'Benji', 'Mila', 'Shiro_Takiki', 'Sailor_Moon', 'Tuxedo_Mask', 'Jotaro_Kujo', 'Dio_Brando', 'Giorno_Giovanna', 'Inuyasha', 'Eren', 'Mikasa', 'Armin', 'Colossal_Titan', 'Armored_Titan', 'Saitama', 'Genos', 'Tanjiro', 'Nezuko', 'Edward_Elric', 'Alphonse_Elric', 'Detective_Conan', 'Goro', 'Yami_Yugi', 'Seto_Kaiba', 'Light_Yagami', 'L', 'Misa_Amane', 'Ryuk', 'Lelouch_VI', 'Okabe_Rintarou', 'Kurisu_Makise', 'Chihiro_Ogiro', 'No-Face', 'Yubaba', 'Lupin_lll', 'Kaneki_Ken', 'Shinji', 'Kenzou_Tenma', 'Shin_Chan']
    },
    SIMPSONS: {
        EU: ['homer_simpson', 'marge_simpson', 'bart_simpson', 'lisa_simpson', 'maggie_simpson', 'abe_simpson', 'santa_little_helper', 'snowball_II', 'patty_bouvier', 'selma_bouvier', 'mr_burns', 'waylon_smithers', 'moe', 'barney_grumble', 'carl_carlson', 'lenny_leonard', 'ned_flanders', 'maude_flanders', 'rod_flanders', 'todd_flanders', 'millhouse_van_houten', 'kirk_van_houten', 'luann_van_houten', 'principal_skinner', 'superintendent_chalmers', 'edna_krabappel', 'elizabeth_hoover', 'mr_largo', 'lunchlady_doris', 'willie', 'nelson_muntz', 'jimbo_jones', 'kearney', 'dolph', 'martin_prince', 'ralph_wiggum', 'uter', 'squeaky_voiced_teen', 'database', 'otto_mann', 'krusty_the_clown', 'mr_teeny', 'itchy', 'scratchy', 'sideshow_bob', 'sideshow_mel', 'bumblebee_man', 'duff_man', 'kent_brockman', 'troy_mcclure', 'chief_wiggum', 'mrs_wiggum', 'officer_lou', 'reverend_lovejoy', 'helen_lovejoy', 'dr_hibbert', 'dr_nick_riviera', 'mayor_joe_quimby', 'judge_constance_harm', 'judge_snyder', 'apu_nahasapeemapetilon', 'manjula_nahasapeemapetilon', 'luigi', 'lionel_hutz', 'blue_haired_lawyer', 'hank_scorpio', 'comic_book_guy', 'herman_hermann', 'professor_frink', 'gil', 'cletus', 'brandine', 'disco_stu', 'artie_ziff', 'snake', 'fat_tony', 'drederick_tatum', 'baby_gerald', 'kang', 'rainier_wolfcastle', 'jasper_beardsley', 'hans_moleman', 'rick_texan', 'agnes_skinner', 'crazy_cat_lady', 'captain_mccallister', 'bleeding_gums_murphy', 'yes_guy', 'plopper', 'frank_grimes_jr'],
        US: ['homer_simpson', 'marge_simpson', 'bart_simpson', 'lisa_simpson', 'maggie_simpson', 'abe_simpson', 'santa_little_helper', 'snowball_II', 'plopper', 'patty_bouvier', 'selma_bouvier', 'ned_flanders', 'maude_flanders', 'moe', 'barney_grumble', 'principal_skinner', 'superintendent_chalmers', 'edna_krabappel', 'mr_largo', 'lunchlady_doris', 'willie', 'otto_mann', 'millhouse_van_houten', 'nelson_muntz', 'jimbo_jones', 'kearney', 'dolph', 'martin_prince', 'ralph_wiggum', 'uter', 'mr_burns', 'waylon_smithers', 'carl_carlson', 'lenny_leonard', 'hank_scorpio', 'krusty_the_clown', 'mr_teeny', 'itchy', 'scratchy', 'sideshow_bob', 'sideshow_mel', 'bumblebee_man', 'duff_man', 'kent_brockman', 'troy_mcclure', 'chief_wiggum', 'officer_lou', 'reverend_lovejoy', 'dr_hibbert', 'dr_nick_riviera', 'mayor_joe_quimby', 'judge_snyder', 'apu_nahasapeemapetilon', 'manjula_nahasapeemapetilon', 'luigi', 'lionel_hutz', 'kirk_van_houten', 'comic_book_guy', 'herman_hermann', 'professor_frink', 'jasper_beardsley', 'hans_moleman', 'rick_texan', 'agnes_skinner', 'crazy_cat_lady', 'captain_mccallister', 'gil', 'cletus', 'disco_stu', 'snake', 'fat_tony', 'kang', 'rainier_wolfcastle', 'bleeding_gums_murphy', 'frank_grimes_jr']
    },
    TV_SERIES: {
        EU: ['walter_white', 'jesse_pinkman', 'saul_goodman', 'kim_wexler', 'mike_ehrmantraut', 'gus_fring', 'tony_soprano', 'michael_scofield', 'jonas_kahnwald', 'sherlock_holmes', 'jon_snow', 'daenerys_targaryen', 'tyrion_lannister', 'arya_stark', 'cersei_lannister', 'thomas_shelby', 'homelander', 'william_butcher', 'starlight', 'seong_gi_hun', 'rachel_green', 'monica_geller', 'phoebe_buffay', 'joey_tribbiani', 'chandler_bing', 'ross_geller', 'barney_stinson', 'jessica_day', 'bojack_horseman', 'wednesday', 'michael_scott', 'dwight_schrute', 'jim_halpert', 'pam_beetsy', 'sheldon_cooper', 'leonard_hofstadter', 'penny_teller', 'phil_dunphy', 'otis_milburn', 'dawson_leery', 'eleven', 'michael_wheeler', 'lucas_sinclair', 'will_byers', 'dustin_henderson', 'dean_winchester', 'sam_winchester', 'rick_grimes', 'negan', 'clarke_griffin', 'homer_simpson', 'marge_simpson', 'peter_griffin', 'stewie_griffin', 'stan_marsh', 'eric_cartman', 'kyle_broflovski', 'kenny_mccormick', 'rick_sanchez', 'morty_smith', 'dr_house', 'meredith_grey', 'shaun_murphy', 'jd', 'the_professor', 'berlin', 'tokyo', 'dr_who', 'queen_elizabeth', 'claire_underwood', 'leroy_jethro_gibbs', 'horatio_caine', 'dexter_morgan', 'james_mcnulty', 'lucifer_morningstar', 'patrick_jane', 'pablo_escobar', 'billy_bob_thornton', 'elliot_alderson', 'dale_cooper', 'jack_shephard', 'dolores_abernathy', 'beth_harmon', 'dana_scully', 'hannah_baker', 'piper_chapman', 'ragnar', 'geralt_of_rivia', 'damon_salvatore', 'buffy_summers'],
        US: ['walter_white', 'jesse_pinkman', 'saul_goodman', 'kim_wexler', 'mike_ehrmantraut', 'gus_fring', 'tony_soprano', 'sherlock_holmes', 'homelander', 'michael_scofield', 'jonas_kahnwald', 'thomas_shelby', 'rick_grimes', 'dean_winchester', 'sam_winchester', 'jon_snow', 'daenerys_targaryen', 'tyrion_lannister', 'arya_stark', 'cersei_lannister', 'the_professor', 'tokyo', 'jack_shephard', 'seong_gi_hun', 'elliot_alderson', 'queen_elizabeth', 'hannah_baker', 'piper_chapman', 'ragnar', 'geralt_of_rivia', 'homer_simpson', 'peter_griffin', 'stan_marsh', 'eric_cartman', 'kyle_broflovski', 'kenny_mccormick', 'rick_sanchez', 'morty_smith', 'bojack_horseman', 'michael_scott', 'dwight_schrute', 'dawson_leery', 'jessica_day', 'beth_harmon', 'dale_cooper', 'rachel_green', 'monica_geller', 'phoebe_buffay', 'joey_tribbiani', 'chandler_bing', 'ross_geller', 'barney_stinson', 'sheldon_cooper', 'phil_dunphy', 'dr_house', 'meredith_grey', 'jd', 'otis_milburn', 'dr_who', 'damon_salvatore', 'eleven', 'michael_wheeler', 'lucas_sinclair', 'will_byers', 'dustin_henderson', 'dexter_morgan', 'leroy_jethro_gibbs', 'horatio_caine', 'patrick_jane', 'james_mcnulty', 'lucifer_morningstar', 'billy_bob_thornton', 'pablo_escobar', 'dana_scully', 'buffy_summers']
    },
    DRAGONBALL: {
        EU: ['goku_normal', 'goku_ssj', 'goku_ssj3', 'goku_ssj4', 'goku_ssj_blue', 'goku_ultra_instinct', 'vegeta', 'vegeta_ssj', 'majin_vegeta', 'vegeta_ssj4', 'vegeta_ssj_blue', 'gohan_kid_freezer_saga', 'gohan_ssj', 'ultimate_gohan', 'future_trunks', 'young_trunks', 'goten', 'gotenks', 'gotenks_ssj3', 'piccolo', 'krillin', 'yamcha', 'tien', 'chiaotzu', 'raditz', 'nappa', 'saibamen', 'great_ape', 'zarbon', 'dodoria', 'guldo', 'recoome', 'burter', 'jeice', 'ginyu', 'frieza', 'golden_frieza', 'king_cold', 'android_16', 'android_17', 'android_18', 'android_19', 'android_20_dr_gero', 'cell', 'dabura', 'babidi', 'majin_buu', 'kid_buu', 'bardock', 'garlic_jr', 'cooler', 'broly', 'janemba', 'oolong', 'puar', 'master_roshi', 'baba', 'grandpa_gohan', 'launch', 'pilaf', 'general_red_red_ribbon', 'tao_bai_bai', 'king_piccolo', 'chi_chi', 'bulma', 'karin', 'yajirobe', 'mr_popo', 'kami', 'dende', 'shenron', 'king_kai', 'mr_satan', 'videl', 'pikkon', 'kaioshin', 'vegito', 'gogeta_ssj4', 'pan', 'uub', 'baby', 'super_c17', 'omega_shenron', 'whis', 'beerus', 'zamasu', 'goku_black', 'hit', 'jiren', 'zeno'],
        US: ['goku_normal', 'goku_ssj', 'goku_ssj3', 'goku_ssj4', 'goku_ssj_blue', 'goku_ultra_instinct', 'vegeta', 'vegeta_ssj', 'majin_vegeta', 'vegeta_ssj4', 'vegeta_ssj_blue', 'gohan_kid_freezer_saga', 'gohan_ssj', 'ultimate_gohan', 'future_trunks', 'young_trunks', 'goten', 'gotenks_ssj3', 'piccolo', 'krillin', 'yamcha', 'tien', 'chiaotzu', 'raditz', 'nappa', 'saibamen', 'great_ape', 'zarbon', 'dodoria', 'guldo', 'recoome', 'burter', 'jeice', 'ginyu', 'frieza', 'android_16', 'android_17', 'android_18', 'android_19', 'android_20_dr_gero', 'cell', 'dabura', 'babidi', 'majin_buu', 'kid_buu', 'bardock', 'broly', 'oolong', 'master_roshi', 'pilaf', 'tao_bai_bai', 'king_piccolo', 'chi_chi', 'bulma', 'karin', 'yajirobe', 'mr_popo', 'dende', 'shenron', 'king_kai', 'mr_satan', 'videl', 'kaioshin', 'gogeta_ssj4', 'pan', 'uub', 'baby', 'super_c17', 'omega_shenron', 'whis', 'beerus', 'zamasu', 'goku_black', 'jiren', 'zeno']
    }
}

export const PATTERNS_ARRAYS = {
    'coverall': [
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1]
    ],
    'inside': [
        [0, 0, 0, 0, 0],
        [0, 1, 1, 1, 0],
        [0, 1, 0, 1, 0],
        [0, 1, 1, 1, 0],
        [0, 0, 0, 0, 0]
    ],
    'outside': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    'fourcorners': [
        [1, 0, 0, 0, 1],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [1, 0, 0, 0, 1]
    ],
    'candycane': [
        [0, 1, 1, 0, 0],
        [1, 0, 0, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0]
    ],
    'airplane': [
        [0, 0, 0, 1, 0],
        [1, 0, 0, 1, 0],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 1, 0],
        [0, 0, 0, 1, 0]
    ],
    'gift': [
        [1, 1, 1, 1, 1],
        [1, 0, 1, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 1, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    '14': [
        [1, 0, 1, 0, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1]
    ],
    'arrow': [
        [0, 0, 1, 1, 1],
        [0, 0, 0, 1, 1],
        [0, 0, 1, 0, 1],
        [0, 1, 0, 0, 0],
        [1, 0, 0, 0, 0]
    ],
    'lips': [
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 1, 1, 0]
    ],
    'sailboat': [
        [0, 0, 1, 0, 0],
        [0, 0, 1, 1, 0],
        [0, 0, 1, 0, 0],
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0]
    ],
    'cocktail': [
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0]
    ],
    'diamond': [
        [0, 0, 0, 0, 0],
        [0, 1, 1, 1, 0],
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 1, 0, 0]
    ],
    'hourglass': [
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0],
        [1, 1, 1, 1, 1]
    ],
    'heart': [
        [1, 1, 0, 1, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 0, 1, 0],
        [0, 0, 1, 0, 0]
    ],
    'fullheart': [
        [1, 1, 0, 1, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 0, 1, 0, 0]
    ],
    'dollars': [
        [1, 1, 1, 1, 1],
        [1, 0, 1, 0, 0],
        [1, 1, 1, 1, 1],
        [0, 0, 1, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    'middlefinger': [
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1]
    ],
    'birthday': [
        [0, 0, 0, 0, 0],
        [0, 0, 1, 0, 0],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1]
    ],
    'anchor': [
        [1, 1, 1, 1, 1],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [1, 0, 1, 0, 1],
        [0, 1, 1, 1, 0]
    ],
    'turtle': [
        [0, 0, 1, 0, 0],
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [1, 0, 0, 0, 1]
    ],
    'snowflake': [
        [1, 0, 1, 0, 1],
        [0, 1, 1, 1, 0],
        [1, 1, 1, 1, 1],
        [0, 1, 1, 1, 0],
        [1, 0, 1, 0, 1]
    ],
    'reindeer': [
        [1, 0, 0, 0, 1],
        [1, 1, 0, 1, 1],
        [0, 1, 1, 1, 0],
        [0, 1, 0, 1, 0],
        [0, 1, 1, 1, 0]
    ],
    'tree': [
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0],
        [1, 1, 1, 1, 1],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0]
    ],
    'bell': [
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0],
        [0, 1, 1, 1, 0],
        [1, 1, 1, 1, 1],
        [0, 0, 1, 0, 0]
    ],
    '1': [
        [0, 0, 1, 0, 0],
        [0, 1, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0]
    ],
    '2': [
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1]
    ],
    '3': [
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    '4': [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [0, 0, 0, 0, 1]
    ],
    '5': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    '6': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    '7': [
        [1, 1, 1, 1, 1],
        [0, 0, 0, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 0, 0, 0],
        [1, 0, 0, 0, 0]
    ],
    '8': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    '9': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    '10': [
        [1, 0, 1, 1, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 1, 1, 1]
    ],
    'A': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1]
    ],
    'B': [
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 0]
    ],
    'C': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1]
    ],
    'D': [
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 0]
    ],
    'E': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1]
    ],
    'F': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 0],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0]
    ],
    'G': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 0, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    'H': [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1]
    ],
    'I': [
        [0, 1, 1, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 1, 1, 0]
    ],
    'J': [
        [1, 1, 1, 1, 1],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [1, 0, 1, 0, 0],
        [1, 1, 1, 0, 0]
    ],
    'K': [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 1, 0],
        [1, 1, 1, 0, 0],
        [1, 0, 0, 1, 0],
        [1, 0, 0, 0, 1]
    ],
    'L': [
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1]
    ], //L
    'M': [
        [1, 0, 0, 0, 1],
        [1, 1, 0, 1, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1]
    ],
    'N': [
        [1, 0, 0, 0, 1],
        [1, 1, 0, 0, 1],
        [1, 0, 1, 0, 1],
        [1, 0, 0, 1, 1],
        [1, 0, 0, 0, 1]
    ],
    'O': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    'P': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 0, 0, 0, 0]
    ],
    'Q': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 1, 1],
        [1, 1, 1, 1, 1]
    ],
    'R': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1],
        [1, 0, 0, 1, 0],
        [1, 0, 0, 0, 1]
    ],
    'S': [
        [1, 1, 1, 1, 1],
        [1, 0, 0, 0, 0],
        [1, 1, 1, 1, 1],
        [0, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    'T': [
        [1, 1, 1, 1, 1],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0]
    ],
    'U': [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 1, 1, 1, 1]
    ],
    'V': [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [0, 1, 0, 1, 0],
        [0, 0, 1, 0, 0]
    ],
    'W': [
        [1, 0, 0, 0, 1],
        [1, 0, 0, 0, 1],
        [1, 0, 1, 0, 1],
        [1, 1, 0, 1, 1],
        [1, 0, 0, 0, 1]
    ],
    'X': [
        [1, 0, 0, 0, 1],
        [0, 1, 0, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 0, 1, 0],
        [1, 0, 0, 0, 1]
    ],
    'Y': [
        [1, 0, 0, 0, 1],
        [0, 1, 0, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0],
        [0, 0, 1, 0, 0]
    ],
    'Z': [
        [1, 1, 1, 1, 1],
        [0, 0, 0, 1, 0],
        [0, 0, 1, 0, 0],
        [0, 1, 0, 0, 0],
        [1, 1, 1, 1, 1]
    ]
}
