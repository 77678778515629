import { Divider } from 'antd'
import React from 'react'

export default function Footer() {

    return (
        <div className="page-footer-section bg-dark fg-white" style={{ paddingTop: 0 }}>
            
            <Divider style={{ background: '#4C4852', margin: 0 }} />

            <div className="container" style={{marginTop: 24}}>
                <div className="row">
                    <div className="col-12 col-md-6 py-2">
                        <p className="d-inline-block ml-2">Copyright &copy; Dried Beans. All rights reserved</p>
                    </div>
                    <div className="col-12 col-md-6 py-2">
                        <ul className="nav justify-content-end">
                            <li className="nav-item"><a href="/termsofservice" className="nav-link">Terms of Service</a></li>
                            <li className="nav-item"><a href="/privacypolicy" className="nav-link">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}