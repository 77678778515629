import { configureStore } from '@reduxjs/toolkit'
import scoreboardSlice from './scoreboardSlice'

export default configureStore({
  reducer: {
    scoreboard: scoreboardSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
})