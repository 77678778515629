import React from 'react'
import logo from './img/icons/logo-not-square.png'
import discordIcon from './img/icons/discord_white_logo.png'

export default function Navbar() {

    return (
        <nav className="navbar navbar-expand-lg navbar-light navbar-floating">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="" width="120" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarToggler">
                    <ul className="navbar-nav ml-lg-5 mt-3 mt-lg-0" style={{ fontSize: '20px' }}>
                        <li className="nav-item">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/tutorial">How to install</a>
                        </li>
                    </ul>
                    <div className="ml-auto my-2 my-lg-0">
                        <a href="https://discord.gg/U9DdwM6" target="_blank" rel="noopener noreferrer" className="btn btn-dark rounded-pill"><img src={discordIcon} className="twitch-icon" alt="twitch" /> Join our Discord</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}